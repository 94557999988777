import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Container, Row, Spinner } from 'reactstrap';

function CvPage() {

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [notificationImgSuccess, setNotificationImgSuccess] = useState(false);
    const [notificationImgNoValida, setNotificationImgNoValida] = useState(false);
    //const [imagePreviewUrl, setImagePreviewUrl] = useState(auth.user.picture ? auth.user.picture : defaultAvatar);

    const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      //setImagePreviewUrl(reader.result);
      console.log(file.size);
      //!file.type.startsWith('file/') && imgNoValida()
    };
    reader.readAsDataURL(file);
  };

    const wrapper = useRef(null);

    useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("cv");
    return function cleanup() {
      document.body.classList.remove("cv");
    };
    }, []);
    
    const onDismissNotificationImgSuccess = () => setNotificationImgSuccess(false);

  const onDismissNotificationImgNoValida = () => setNotificationImgNoValida(false);

    return (
        <div className="wrapper" ref={wrapper}>
            <div className="section">
                <Container>
                    <h2 style={{ color: "#bc2734", textAlign: "center" }}>Curriculum Vitae</h2>
                    <Row xs="1">
                    <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
       }}>
      {loading ? 
      
        <div className="fileinput text-center">
          <Spinner color="primary" />
        </div>
      
      : (
        
        <>
          <Alert color="success" isOpen={notificationImgSuccess} toggle={onDismissNotificationImgSuccess}>
              <b>Imagen cargada con Exito </b>
          </Alert>
          <Alert color="danger" isOpen={notificationImgNoValida} toggle={onDismissNotificationImgNoValida}>
              <b>Error al cargar - </b>
              Recuerda que solo puedes cargar archivos de tipo Imagen. Intenta con una diferente por favor.
          </Alert>
          <div className="fileinput text-center">
            <input className="imageUpload" type="file" onChange={handleImageChange} /* ref={fileInput} */ />
            <div className="perfil">
              
            </div>
            <div>
              {file === null ? (
                <Button
                  color="primary"
                  className="primary"
                  //onClick={() => handleClick()}
                >
                  Sube tu CV
                </Button>
              ) : (
                <span>
                  <Button
                    color="primary"
                    className="primary"
                    //onClick={() => handleUpload()}
                  >
                    Guardar imagen
                  </Button>
                  <Button
                    color="primary"
                    className="primary"
                    //onClick={() => handleRemove()}
                  >
                    <i className="fa fa-times" /> Remove
                  </Button>
                </span>
              )}
            </div>
          </div>
          </>
      )
    }
    
  </div>  
                    </Row>
                </Container>
            </div>
        </div>    
    )
}

export default CvPage
