import React from "react";
import Meta from "./../components/Meta";

function NotFoundPage(props) {
  return (
    <>
      <Meta title="404" />
      <div
        style={{
          padding: "50px",
          width: "100%",
          textAlign: "center",
        }}
      >
        La página que buscas <code>{props.location.pathname}</code> no esta disponible.
      </div>
    </>
  );
}

export default NotFoundPage;
