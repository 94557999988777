import React from 'react';
import Meta from '../components/Meta';
import CodigosSection from '../components/CodigosSection';
import { requireAuth } from '../util/auth';

function CodigosPage() {
    return (
        <>
        <Meta title="Administracion de Codigos de Descuentos" />
        <CodigosSection
            bg="white"
            textColor="black"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title="Codigos Promocionales"
            subtitle="Administracion de los Codigos de Descuentos"
        />
    </>   
    );
}

export default requireAuth(CodigosPage);
