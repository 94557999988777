import React, { useState } from 'react'
import { useInstanciasCursoPorFecha } from '../util/db';
import FormAlert from './FormAlert';
import { Spinner } from 'react-bootstrap';
import { Button, Card, CardBody } from 'reactstrap';
import Moment from 'react-moment';
import ModalInscritos from './ModalInscritos';


function TablaInscripcionesPorCurso(props) {

    const [modalInscritos, setModalInscritos] = useState(null)

    const {
    data: datos,
    status: datosStatus,
    error: datosError,
    } = useInstanciasCursoPorFecha(props.keyCurso);

  const datosAreEmpty = !datos || datos.length === 0;


  return (
      <>
        {datosError && (
            <div className="mb-3">
                <FormAlert type="error" message={datosError.message} />
            </div>
        )}
        
        {(datosStatus === "loading" || datosAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {datosStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Cargando...</span>
                </Spinner>
              )}

              {datosStatus !== "loading" && datosAreEmpty && (
                <>No hay ningun registro de programacion de fechas para este curso.</>
              )}
            </div>
        )}
        
        {datosStatus !== "loading" && datos && datos.length > 0 && (
              datos.map((instanciaCurso, index) => (
                  <Card key={index} className="card-pricing" style={{backgroundColor: '#900C3F'}}>
                    <CardBody>
                    <h3 className="title">
                        <Moment format="MMMM YYYY">
                    {instanciaCurso.fechaInicio}</Moment>
                    </h3>
                    <ul>
                        <li><Moment format="D MMMM">
                    {instanciaCurso.fechaInicio}
                    </Moment> / <Moment format="D MMMM">
                    {instanciaCurso.fechaFin}
                    </Moment></li>
                        <li>{instanciaCurso.diasCurso}</li>
                        <li>{instanciaCurso.horario}</li>
                        <li>Fecha Limite de Inscripción: <Moment format="D MMMM YYYY">
                    {instanciaCurso.fechaInicio}
                    </Moment></li>
                    </ul>
                    <Button
                        className="btn-round"
                        color="info"
                              onClick={() => setModalInscritos({
                                  id: instanciaCurso.id,
                                  nombre: instanciaCurso.nombre,
                                  fechaInicio: instanciaCurso.fechaInicio,
                              })}
                    >
                        Inscritos
                    </Button>
                    </CardBody>
                </Card>
              ))
        )}
        
          {modalInscritos && (
              <ModalInscritos
                  id={modalInscritos.id}
                  nombreCurso={modalInscritos.nombre}
                  fechaInicio={modalInscritos.fechaInicio}
                  onDone={() => { setModalInscritos(null) }} />
        )}
          
      </>
  )
}

export default TablaInscripcionesPorCurso