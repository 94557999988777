import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import ModeloNegocio from "./../components/ModeloNegocio"
import FeaturesSection from "./../components/FeaturesSection";
//import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
// import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";
import VideoHeader from "../assets/video/video.mp4"
import CarouselSection from "../components/CarouselSection";
import TextAnimation from "../assets/animations/TextAnimation";
import Subtitle2 from "../components/Subtitle2";
import SlickerCursos2 from "../components/SlickerCursos2";

import { useAuth } from "../util/auth";
import { Redirect } from "react-router";


function EnConstruccionPage(props) {
  const auth = useAuth();
  return (
    <>
      {auth.user && !auth.user.name && (
        //<DashboardPrincipal perfil={auth.user.perfil} />
        <Redirect to="/" />
      )}
      <Meta />
      <HeroSection
        bg="#ffffff"
        textColor="dark"
        size="lg"
        bgImage="https://source.unsplash.com/ugnrXk1129g/1600x800"
        bgVideo={VideoHeader}
        bgImageOpacity={0}
        title={<TextAnimation />}
        colorTitle="#bc2734"
        title2={<Subtitle2 />}
        subtitle="En Construcción"
        buttonText="En Construcción"
        buttonColor="danger"
        buttonPath="/"
      />
      {/* <ModeloNegocio />
      <SlickerCursos2 />
      <FeaturesSection
        bg="white"
        textColor="dark"
        colorTitle="#000000"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Caracteristicas"
        subtitle=""
      /> */}
      {/* <ClientsSection
        bg="white"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Aliados con los mejores"
        subtitle=""
      /> */}
      {/* <CarouselSection
        bg="white"
        textColor="dark"
        colorTitle="#000000"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Nuestros Servicios"
        subtitle=""
      />
      <TestimonialsSection
        bg="white"
        textColor="black"
        colorTitle="#000000"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Testimonios"
        subtitle=""
      /> */}
      {/* <NewsletterSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      /> */}
{/*       <CtaSection
        bg="white"
        textColor="black"
        colorTitle="#000000"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="¿Listo para comenzar?"
        subtitle=""
        buttonText="Iniciar"
        buttonColor="danger"
        buttonPath="/pricing"
      /> */}
    </>
  );
}

export default EnConstruccionPage;
