import React, { useState } from 'react';
import FormAlert from "./FormAlert";
import { useMessages, updateMessage } from '../util/db';
import { Button, Card, Spinner } from 'react-bootstrap';
import ListGroup from "react-bootstrap/ListGroup";
import EditMessageModal from './EditMessageModal'

function DashboardMensajes() {

    const [alert, setAlert] = useState(null);
    const [deletingMessage, setDeletingMessage] = useState(null);

    const {
    data: datos,
    status: datosStatus,
    error: datosError,
    } = useMessages();
    
    const datosAreEmpty = !datos || datos.length === 0;

    const handleActivation = (mensaje) => {
        updateMessage(mensaje.id, { leido: !mensaje.leido });
        if ( mensaje.activo === true )
            setAlert({message: "Se ha marcado el mensaje como leido"})
        else setAlert({message: "Se ha desmarcado el mensaje como leido"});
  };

    return (
       <>
      {datosError && (
        <div className="mb-3">
          <FormAlert type="error" message={datosError.message} />
        </div>
      )}
      
      {alert && (
        <div className="mb-3">
          <FormAlert type="success" message={alert.message} dismissible onClose={() => setAlert(null)}/>
        </div>
      )}

      <div className="DashboardItems__card-items-wrapper">
        <Card className="card-items" style={{backgroundColor: "white", color: "black"}}>
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center" style={{ backgroundColor: "white", color: "black" }}
          >
            Mensajes de Usuarios
          </Card.Header>

          {(datosStatus === "loading" || datosAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {datosStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Cargando...</span>
                </Spinner>
              )}

              {datosStatus !== "loading" && datosAreEmpty && (
                <>No hay ningun mensaje registrado</>
              )}
            </div>
          )}

          {datosStatus !== "loading" && datos && datos.length > 0 && (
            <ListGroup variant="flush">
              {datos.map((mensaje, index) => (
                <ListGroup.Item
                  key={index}
                  style={{backgroundColor: (mensaje.leido ? "lightgreen" : "gold")}}
                  className={
                    'd-flex justify-content-between align-items-center'
                  }
                >
                      {mensaje.name} - {mensaje.email} - {mensaje.message}
                  <div>
                    <Button
                      variant="link"
                      aria-label="star"
                      onClick={() => handleActivation(mensaje)}
                      className={
                        `action` + (mensaje.activo ? ` star-featured` : "")
                      }
                    >
                      Marcar <i className="fas fa-star" />
                    </Button>
                    <a href={`mailto:${mensaje.email}?subject=Intellectus%20exD&body=Estimado%20${mensaje.name},`} ><Button
                      variant="link"
                      aria-label="update"
                      onClick={() => handleActivation(mensaje)}
                      className="action"
                      >
                      Contestar <i className="fas fa-edit" />
                      </Button></a>
                      
                    
                    <Button
                      variant="link"
                      aria-label="delete"
                      onClick={() => setDeletingMessage(mensaje.id)}
                      className="action"
                    >
                      Eliminar <i className="fas fa-trash" />
                    </Button>
                    
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Card>
      </div>
    

        {deletingMessage && (
            <EditMessageModal
                id={deletingMessage}
                onDone={(success) => {
                    setDeletingMessage(null);
                    success === true && setAlert({message: "Se actualizo correctamente el codigo de Promocion"})
                }}
            />
      )}
      
    </>
    );
}

export default DashboardMensajes;
