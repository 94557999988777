import React, { useState } from 'react';
import { Table } from 'reactstrap';
import LongContent from './LongContent';
import FormAlert from './FormAlert';
import { usePagosPorUsuario } from '../util/db';
import { Spinner } from 'react-bootstrap';
import { useAuth } from '../util/auth';

const Pagos = () => {

  const auth = useAuth();

  const [formAlert, setFormAlert] = useState(null);

  const {
    data: datos,
    status: datosStatus,
    error: datosError,
    } = usePagosPorUsuario(auth.user.uid);

  const datosAreEmpty = !datos || datos.length === 0;

  return (
    <>
      <LongContent>
        
        {datosError && (
        <div className="mb-3">
          <FormAlert type="error" message={datosError.message} />
        </div>
        )}

        {formAlert && (
                <div className="mb-3">
                <FormAlert type="success" message={formAlert.message} onClose={() => setFormAlert(false)} dismissible/>
                </div>
        )}


        {(datosStatus === "loading" || datosAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {datosStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Cargando...</span>
                </Spinner>
              )}

              {datosStatus !== "loading" && datosAreEmpty && (
                <>No hay ningun registro de pago realizado.</>
              )}
            </div>
        )}

          <Table responsive>
            <thead>
                <tr>
                    <th className="text-center">Estado</th>
                    <th className="text-center">Producto</th>
                    <th className="text-center">Fecha de Pago</th>
                    
                </tr>
            </thead>
            <tbody>
            {datosStatus !== "loading" && datos && datos.length > 0 && (
              datos.map((pago, index) => (
                <tr key={index}>
                  <td className="text-center">{pago.status}</td>
                  <td className="text-center">{pago.product.description}</td>
                  <td className="text-center">{!pago.createdAt?.seconds ? (
                        <Spinner color="primary" />
                            ) : (
                    Intl.DateTimeFormat("es-MX", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    }).format(
                        pago.createdAt.seconds * 1000
                    )
                    )}</td>
                  
                </tr>
              ))
            )}    
            </tbody>
          </Table>

        </LongContent>
    </>
  );
}

export default Pagos;
