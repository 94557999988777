import React from 'react';
import AdminSection from '../components/AdminSection';
import Meta from '../components/Meta';
import { requireAuth } from '../util/auth';

function AdminPage() {
    return (
    <>
        <Meta title="Administracion" />
        <AdminSection
            bg="white"
            textColor="black"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title="Administracion de Inicios de Cursos"
            subtitle=""
        />
    </>    
        
        
  );
}

export default requireAuth(AdminPage);
