import React, { useState } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js'
import { guardarPago, updateUserSuscription, guardarSuscripcionEnUsuario, guardarCursoEnUsuario } from '../util/db';
import { useAuth } from '../util/auth';

function PaypalCheckoutButton(props) {

    const auth = useAuth();

    //const [paidFor, setPaidFor] = useState(false)
    const [error, setError] = useState(null)

    const { product } = props;
    const uid = props.uid;
    const esSuscripcion = props.suscripcion;

    const handleApprove = (order) => {
        guardarPago(order, product, uid);
        if (esSuscripcion === true) {
            updateUserSuscription(auth.user.uid, { suscripcion: product.description })
            guardarSuscripcionEnUsuario(order, product, uid);
        } else {
            guardarCursoEnUsuario(order, product, uid)
         }
        /* esSuscripcion === true && updateUserSuscription(auth.user.uid, {suscripcion: product.description}) */
        props.onDone()
        // Actualizar estado del usuario
    }
    
    if (error) {
        //Mostrar que el pago es correcto
        alert('Error en Checkout de Paypal', error)
    }

    return (
        <>
            <PayPalButtons
                style={{color: "gold", label: "paypal", shape: "pill",}}
                onClick={(data, actions) => {
                    
                    //revisar si ya se cuenta con este producto
                    const hasAlreadyBought = false;

                    if (hasAlreadyBought) {
                        setError("Ya has adquirido este elemento, revisa en tus suscripciones.")
                        return actions.reject()
                    } else { 
                        return actions.resolve()
                    }
                    
                }}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            description: product.description,
                            amount: {
                                value: product.price
                            }
                        }]
                    })
                }}
                onApprove={async(data, actions) => {
                    const order = await actions.order.capture();
                    handleApprove(order);
                    
                }}
                onCancel={() => {
                    
                }}
                onError={(error) => {
                    setError(error);
                    console.error("Error Paypal Checkout",error)
                }}
                
            />
        </>
    );
}

export default PaypalCheckoutButton;
