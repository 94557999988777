import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Col, Row } from 'reactstrap'
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function PdfVisor(props) {

    const filePDF = props.file.default

    //console.log(filePDF)

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }
    
        const pdfSize = 400;

    return (
        <>
          <Row className="row-grid justify-content-center">
                  <Col lg="10">
                    <div className="info">
                        <h4 className="info-title">{props.title}</h4>
                        <hr className="line-danger" />
                        <Document
                            file={filePDF}
                            onLoadSuccess={onDocumentLoadSuccess}
                            >
                            <Page pageNumber={pageNumber} width={pdfSize}/>
                        </Document>
                        <div>
                            <p>
                                Pagina {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
                            </p>

                            <div className="justify-content-center">

                            {!(pageNumber <= 1) &&
                      
                            <Button
                                className="btn-icon btn-round ml-1"
                                color="danger"
                                type="button"
                                onClick={previousPage}
                            >
                                <i className="fa fa-angle-double-left" />
                            </Button>
                            
                            }
                      
                            {!(pageNumber >= numPages) && 
                    
                            <Button
                                className="btn-icon btn-round ml-1"
                                color="danger"
                                type="button"
                                onClick={nextPage}
                            >
                                <i className="fa fa-angle-double-right" />
                            </Button>
                            
                            }
                      
                            </div>
                                <Button className="btn-round ml-1" color="danger" type="button" href = {filePDF}>Descargar
                                </Button>
                        </div>
                    </div>
                  </Col>
                </Row>  
        </>
    )
}
