import React from 'react';

import {
    Carousel,
  CarouselItem,
  CarouselIndicators,
    Container,
    Row,
    Col,
    Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
} from 'reactstrap';


const items2 = [
  {
        content: (
          <Row>
              <Col className="ml-auto mr-auto" lg="10">
                  <Card className="card-profile card-horizontal" style={{ backgroundColor: "#1d8cf8" }}>
                  <Row>
                    <Col xl="7">
                      <div className="card-image no-mask">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("../assets/img/capacitacion.jpg").default}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col xl="5">
                      <CardBody className="mt-3">
                        <CardTitle tag="h2">Capacitación</CardTitle>
                        
                        {/* <CardText tag="h4">Descripcion</CardText>
                        
                        <CardText tag="h6">Contenido</CardText> */}
                       
                      </CardBody>
                      <CardFooter>
                      </CardFooter>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
        ),
        altText: "",
        caption: "",
        src: "0",
  },
  {
        content: (
          <Row>
              <Col className="ml-auto mr-auto" lg="10">
                <Card className="card-profile card-horizontal" style={{ backgroundColor: "#5603ad" }}>
                  <Row>
                    <Col xl="7">
                      <div className="card-image no-mask">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("../assets/img/emprendimiento.jpg").default}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col xl="5">
                      <CardBody className="mt-3">
                        <CardTitle tag="h2">Emprendimiento</CardTitle>
                        
                        {/* <CardText tag="h4">Descripcion</CardText>
                        
                        <CardText tag="h6">Contenido</CardText> */}
                       
                      </CardBody>
                      <CardFooter>
                      </CardFooter>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
        ),
        altText: "",
        caption: "",
        src: "1",
  },
  {
        content: (
          <Row>
              <Col className="ml-auto mr-auto" lg="10">
                <Card className="card-profile card-horizontal" style={{backgroundColor: "#fb6340"}}>
                  <Row>
                    <Col xl="7">
                      <div className="card-image no-mask">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("../assets/img/tecnologia.jpg").default}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col xl="5">
                      <CardBody className="mt-3">
                        <CardTitle tag="h2">Tecnología</CardTitle>
                        
                        {/* <CardText tag="h4">Descripcion</CardText>
                        
                        <CardText tag="h6">Contenido</CardText> */}
                       
                      </CardBody>
                      <CardFooter>
                      </CardFooter>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
        ),
        altText: "",
        caption: "",
        src: "2",
  },{
        content: (
          <Row>
              <Col className="ml-auto mr-auto" lg="10">
                <Card className="card-profile card-horizontal" style={{backgroundColor: "#e14eca"}}>
                  <Row>
                    <Col xl="7">
                      <div className="card-image no-mask">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("../assets/img/consultoria.jpg").default}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col xl="5">
                      <CardBody className="mt-3">
                        <CardTitle tag="h2">Consultoría</CardTitle>
                        
                        {/* <CardText tag="h4">Descripcion</CardText>
                        
                        <CardText tag="h6">Contenido</CardText> */}
                       
                      </CardBody>
                      <CardFooter>
                      </CardFooter>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
        ),
        altText: "",
        caption: "",
        src: "3",
  },
];

//Borrar hasta aqui para retornar al componente funcional


const ControlledCarousel = (props) => {

    const [carousel1Index, setCarousel1Index] = React.useState(0);
  const [carousel2Index, setCarousel2Index] = React.useState(0);
  const [animating1, setAnimating1] = React.useState(false);
  const [animating2, setAnimating2] = React.useState(false);
  const onExiting = (carousel) => {
    if (carousel === 1) {
      setAnimating1(true);
    } else {
      setAnimating2(true);
    }
  };

  const onExited = (carousel) => {
    if (carousel === 1) {
      setAnimating1(false);
    } else {
      setAnimating2(false);
    }
  };
  const next = (carousel, items) => {
    if (carousel === 1) {
      if (animating1) {
        return;
      }
    } else {
      if (animating2) {
        return;
      }
    }
    let currentIndex = -1;
    if (carousel === 1) {
      currentIndex = carousel1Index;
    } else {
      currentIndex = carousel2Index;
    }
    const nextIndex = currentIndex === items.length - 1 ? 0 : currentIndex + 1;
    if (carousel === 1) {
      setCarousel1Index(nextIndex);
    } else {
      setCarousel2Index(nextIndex);
    }
  };
  const previous = (carousel, items) => {
    if (carousel === 1) {
      if (animating1) {
        return;
      }
    } else {
      if (animating2) {
        return;
      }
    }
    let currentIndex = -1;
    if (carousel === 1) {
      currentIndex = carousel1Index;
    } else {
      currentIndex = carousel2Index;
    }
    const nextIndex = currentIndex === 0 ? items.length - 1 : currentIndex - 1;
    if (carousel === 1) {
      setCarousel1Index(nextIndex);
    } else {
      setCarousel2Index(nextIndex);
    }
  };
  const goToIndex = (newIndex, carousel) => {
    if (carousel === 1) {
      if (animating1) {
        return;
      }
    } else {
      if (animating2) {
        return;
      }
    }
    if (carousel === 1) {
      setCarousel1Index(newIndex);
    } else {
      setCarousel2Index(newIndex);
    }
  };
      
    return (
        <Container>
           <Row>
              <Col md="12">
                <Carousel
                  activeIndex={carousel2Index}
                  next={() => next(2, items2)}
                  previous={() => previous(2, items2)}
                >
                  <CarouselIndicators
                    items={items2}
                    activeIndex={carousel2Index}
                    onClickHandler={(newIndex) => goToIndex(newIndex, 2)}
                  />
                  {items2.map((item, key) => {
                    return (
                      <CarouselItem
                        onExiting={() => onExiting(2)}
                        onExited={() => onExited(2)}
                        key={key}
                        className="justify-content-center"
                      >
                        {item.content}
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous(2, items2);
                    }}
                    role="button"
                  >
                    <i className="tim-icons icon-minimal-left" />
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next(2, items2);
                    }}
                    role="button"
                  >
                    <i className="tim-icons icon-minimal-right" />
                  </a>
                </Carousel>
              </Col>
            </Row>
        </Container>
    )
    
    
  
}

export default ControlledCarousel;