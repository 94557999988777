import React from "react";
import "./../styles/global.scss";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import FormularioPage from "./formulario";
import PricingPage from "./pricing";
//import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import ColorNavbarWhite from "../components/ColorNavbarWhite";
//import CursosPage from "./cursos";
import CvPage from "./cv";
import CursoPage from "./curso";
import ProgramaPage from "./programa"
import TemarioPage from "./temario"
import AdminPage from "./admin"
import PagosPage from "./pagos"
import CodigosPage from "./codigos" 
import MensajesPage from "./mensajes" 
import InscripcionesPage from "./inscripciones"
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from "../util/router";
import EnConstruccionPage from "./enConstruccion";


function App(props) {
  Cookies.set("test", "nice");
  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "MXN",
      }}
    >
      <QueryClientProvider>
        <AuthProvider>
          <Router>
            <>
              {/* <NavbarCustom
              bg="white"
              variant="light"
              expand="md"
              logo="https://uploads.divjoy.com/logo.svg"
            /> */}

              <ColorNavbarWhite
                logo={require("../assets/img/logo.png").default}
              />
              <CookieConsent
                /* enableDeclineButton
            declineButtonText="Declinar (opcional)"
            onDecline={() => {
              resetCookieConsentValue();

            }} */
                location="bottom"
                buttonText="De Acuerdo"
                cookieName="IntellectusCookie"
                style={{ background: "#A8A9AA" }}
                buttonStyle={{
                  color: "#ffffff",
                  fontSize: "13px",
                  backgroundColor: "#BF0022",
                }}
                debug={false}
                expires={150}
              >
                Utilizamos cookies propias y de terceros para mejorar nuestros
                servicios. <br />
                <Link to="/legal/terms-of-service">
                  <span style={{ fontSize: "16px", color: "#0D64FF" }}>
                    Política de Cookies
                  </span>
                </Link>
              </CookieConsent>

              <Switch>
                <Route exact path="/" component={EnConstruccionPage} />

                <Route exact path="/admin" component={AdminPage} />

                <Route exact path="/pagos" component={PagosPage} />

                <Route exact path="/about" component={AboutPage} />

                <Route exact path="/faq" component={FaqPage} />

                <Route exact path="/contact" component={ContactPage} />

                <Route exact path="/formulario" component={FormularioPage} />

                <Route exact path="/pricing" component={PricingPage} />

                {/* <Route exact path="/auth/:type" component={AuthPage} /> */}

                <Route
                  exact
                  path="/settings/:section"
                  component={SettingsPage}
                />

                <Route exact path="/legal/:section" component={LegalPage} />

                {/*               <Route exact path="/curso/:section" component={CursoPage} />

              <Route exact path="/programa/:section" component={ProgramaPage} />
              
              <Route exact path="/temario/:section" component={TemarioPage} /> */}

                <Route exact path="/codigos" component={CodigosPage} />

                <Route exact path="/mensajes" component={MensajesPage} />

                <Route
                  exact
                  path="/inscripciones"
                  component={InscripcionesPage}
                />

                <Route exact path="/cv" component={CvPage} />

                <Route
                  exact
                  path="/firebase-action"
                  component={FirebaseActionPage}
                />

                <Route component={NotFoundPage} />
              </Switch>

              <Footer
                bg="white"
                textColor="black"
                size="md"
                bgImage=""
                bgImageOpacity={1}
                description="Enfoque para un desarrollo extraordinario"
                copyright={`© ${new Date().getFullYear()} Intellectus eXD`}
                logo={require("../assets/img/logo.png").default}
              />
            </>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
    </PayPalScriptProvider>
  );
}

export default App;
