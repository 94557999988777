import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import LongContent from './LongContent';
import FormAlert from './FormAlert';
import CursoModal from './CursoModal';
import { useCursosPorFecha } from '../util/db';
import { Spinner } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment/locale/es';
import ModalEliminarInstanciaCurso from './ModalEliminarInstanciaCurso';
//import moment from 'moment';

const InicioCursos = () => {

  const [creatingFechaInicioCurso, setCreatingFechaInicioCurso] = useState(false);
  const [editingFechaInicioCurso, setEditingFechaInicioCurso] = useState(false)
  const [formAlert, setFormAlert] = useState(null);
  const [deletingFechaInicioCurso, setDeletingFechaInicioCurso] = useState(null);
  const [alert, setAlert] = useState(null);

  const {
    data: datos,
    status: datosStatus,
    error: datosError,
    } = useCursosPorFecha();

  const datosAreEmpty = !datos || datos.length === 0;

  return (
    <>
      <LongContent>
        
        {datosError && (
        <div className="mb-3">
          <FormAlert type="error" message={datosError.message} />
        </div>
        )}

        {formAlert && (
                <div className="mb-3">
                <FormAlert type="success" message={formAlert.message} onClose={() => setFormAlert(false)} dismissible/>
                </div>
        )}

        {alert && (
        <div className="mb-3">
          <FormAlert type="success" message={alert.message} dismissible onClose={() => setAlert(null)}/>
        </div>
      )}

        <Button className="btn" color="primary" size="sm" onClick={() => setCreatingFechaInicioCurso(true)}>
          Nueva Instancia de Curso
        </Button>

        {(datosStatus === "loading" || datosAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {datosStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Cargando...</span>
                </Spinner>
              )}

              {datosStatus !== "loading" && datosAreEmpty && (
                <>No hay ningun registro de Curso pendiente.</>
              )}
            </div>
        )}

          <Table responsive>
            <thead>
                <tr>
                    <th className="text-center">Clave</th>
                    <th className="text-center">Nombre</th>
                    <th className="text-center">Fecha de Inicio</th>
                    <th className="text-center">Fecha de Termino</th>
                    <th className="text-center">Dias de Curso</th>
                    <th className="text-center">Horario</th>
                    <th className="text-right">Acciones</th>
                    {/* <th className="text-right">Inicio de Curso en</th> */}
                </tr>
            </thead>
            <tbody>
            {datosStatus !== "loading" && datos && datos.length > 0 && (
              datos.map((curso, index) => (
                <tr key={index}>
                  <td className="text-center">{curso.key}</td>
                  <td className="text-center">{curso.nombre}</td>
                  <td className="text-center"><Moment format="D MMMM YYYY">
                    {curso.fechaInicio}
                    </Moment></td>
                  <td className="text-center"><Moment format="D MMMM YYYY">
                    {curso.fechaFin}
                    </Moment></td>
                  <td className="text-center">{curso.diasCurso}</td>
                  <td className="text-center">{curso.horario}</td>
                  <td className="text-right">
                    <Button className="btn-icon btn-round" color="info" size="sm">
                      <i className="fa fa-user"></i>
                    </Button>{` `}
                    <Button className="btn-icon btn-round" color="success" size="sm" onClick={() => setEditingFechaInicioCurso(curso.id)}>
                      <i className="fa fa-edit"></i>
                    </Button>{` `}
                    <Button className="btn-icon btn-round" color="danger" size="sm" onClick={() => setDeletingFechaInicioCurso(curso.id)}>
                      <i className="fa fa-times" />
                    </Button>{` `}
                  </td>
                  {/* <td className="text-center">
                    {moment(curso.fechaInicio).fromNow()}
                  </td> */}
                </tr>
              ))
            )}    
            </tbody>
          </Table>

        {creatingFechaInicioCurso && (
          <CursoModal onDone={() => {setCreatingFechaInicioCurso(false); setFormAlert({type: 'success', message: 'Se ha agregado correctamente una nueva fecha de inicio de curso'})} } title="Crear" />
        )}
        
        {editingFechaInicioCurso && (
          <CursoModal id={editingFechaInicioCurso} onDone={() => {setEditingFechaInicioCurso(false); setFormAlert({type: 'success', message: 'Se ha agregado correctamente una nueva fecha de inicio de curso'})} } title="Crear" />
        )}
        
        {deletingFechaInicioCurso && (
            <ModalEliminarInstanciaCurso
                id={deletingFechaInicioCurso}
                onDone={(success) => {
                    setDeletingFechaInicioCurso(null);
                    success === true && setAlert({message: "Se elimino correctamente la fecha de inicio de curso seleccionada"})
                }}
            />
      )}

        </LongContent>
    </>
  );
}

export default InicioCursos;
