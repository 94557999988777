import React from 'react';
import Meta from '../components/Meta';
import MensajesSection from '../components/MensajesSection';
import { requireAuth } from '../util/auth';

function MensajesPage() {
    return (
        <>
        <Meta title="Mensajes" />
        <MensajesSection
            bg="white"
            textColor="black"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title="Mensajes"
            subtitle="Mensajes Recibidos por los Usuarios"
        />
    </>   
    );
}

export default requireAuth(MensajesPage);
