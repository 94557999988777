import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useAuth } from "./../util/auth";
import "./PricingSection.scss";
import { Badge, CardFooter } from "reactstrap";
//import Paypal from "../components/Paypal"

import { suscripciones } from "../util/datos"
import FormAlert from "./FormAlert";
import PaypalCheckoutButton from "./PaypalCheckoutButton";

function PricingSection(props) {

  //const product = { description: "Pricing", price: 20.00}

  const auth = useAuth();

  const [formAlert, setFormAlert] = useState(null);

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        {formAlert && (
          <FormAlert type={formAlert.type} message={formAlert.message} />
        )}
        <Row className="justify-content-center">
          {suscripciones.map((item, index) => (
            <Col
              md={12}
              lg={4}
              className="py-3 d-flex align-items-stretch"
              key={index}
            >
              <Card className="w-100"
                style={{ backgroundColor: (((auth.user.suscripcion === item.id) || ((item.id === 'noMiembro' && !auth.user.suscripcion) )) ? '#32325d' : '#253793') }}>
                <Card.Body className="d-flex flex-column p-4">
                  <Badge color="info" className="ml-1">
                  {(((auth.user.suscripcion === item.id) || ((item.id === 'noMiembro' && !auth.user.suscripcion) )) ? 'Actual' : '')}
                  </Badge>
                  <h5 className="font-weight-bold mb-4">{item.name}</h5>
                  <h1 className="font-weight-bold mb-3">
                    ${item.price}
                    {/* <small className="PricingSection__period">/mes</small> */}
                  </h1>

                  {item.description && (
                    <Card.Text className="mb-4">{item.description}</Card.Text>
                  )}

                  {item.perks && (
                    <Card.Text as="span" className="mt-2 mb-3">
                      <ul className="list-unstyled">
                        {item.perks.map((perk, index) => (
                          <li className="py-1" key={index}>
                            <i className="fas fa-check text-success mr-3" />
                            {perk}
                          </li>
                        ))}
                      </ul>
                    </Card.Text>
                  )}

                  {auth.user &&
                  
                   <CardFooter className="text-center">
                     
                      {/*{item.price !== '0' &&  <Paypal value={item.price} description={item.id} uid={auth.user.uid} onDone={() => {setFormAlert({ type: 'success', message: 'El Pago se ha creado correctamente' })}}/> */ }
                      {((item.price !== '0') && (auth.user.suscripcion !== item.id)) && <PaypalCheckoutButton
                        product={{description: item.id, price: item.price}}
                        uid={auth.user.uid}
                        suscripcion={true}
                        onDone={() => { setFormAlert({ type: 'success', message: 'El Pago se ha creado correctamente' }) }} />}
                  </CardFooter>
            
                  }
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default PricingSection;
