import React from "react";
import {  Button } from "reactstrap";
import {  useAuth } from "../util/auth";

const LogoutButton = () => {
  
  const auth = useAuth();

  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        auth.signout();
      }}
      className="nav-link d-lg-block"
      color="default"
      target="_blank"
    >
      <i className="tim-icons icon-spaceship" /> Cerrar Sesion
    </Button>
    
  );
};

export default LogoutButton;
