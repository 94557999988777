import { Row, Col, List } from "reactstrap"


export const cursos = [
  {
    key: "NIF_IF_180801",
    nombre: "Instrumentos Financieros - Normas de Información Financiera 2022",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "12 Horas",
    puntosEducacionContinua: "0",
    curso: "Instrumentos Financieros",
    linkTemario: "/",
    precioNoMiembro: 3600,
    precioMiembro: 2880,
    precioParticipanteRecurrente: 3600,
    imageCard: "1.jpg",
    pdf: "nif_if_180801.pdf",
    contenido: (
      <>
      <p>Este curso es parte del <a href="/programa/NIF_IF" >Programa de Actualización de Normas de Información Financiera</a> (NIFs) 2022, el cual es un plan de estudios integral que le brindará la capacitación detallada, el conocimiento y la orientación práctica en las NIFs.</p>
      <p>Las necesidades de inversión y financiamiento actuales han originado que se genere una amplia gama de instrumentos financieros, los cuales permiten satisfacer dichas necesidades con gran flexibilidad, y los organismos reguladores activamente están emitiendo normas que faciliten el reconocimiento, valuación y revelación de las transacciones que involucren el uso de instrumentos financieros.</p>
      <p>En este curso se abordan trece normas referentes a los instrumentos financieros, las cuales han sido emitidas por el Consejo Mexicano de Normas de Información Financiera, A.C. (CINIF), con las que se facilita el reconocimiento de los instrumentos financieros para los inversionistas (NIF C-2, C-3, C-14, C-16 y C-20) y para quienes requieren de fondeo a través de instrumentos financieros (NIF C-11, C-12, C-19 y B-12) y así como quienes gestionan Instrumentos Financieros Derivados (NIF C-10) y criptomonedas (NIF C-22)  y otras normas complementarias como NIF B-17, C-1 .</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
        <p>Nota: Los cursos individuales comprados dentro del último año se pueden aplicar a la compra del Programa de Actualización de Normas de Información Financiera. <a href="/temario/NIF_IF_180801" >Más Información aquí</a></p>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">Instrumentos Financieros - Normas de Información Financiera 2022</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
            <div className="mt-5">
              <p>Este curso aborda las Normas de Información Financiera que establecen los requisitos para el reconocimiento, valuación y revelación de los instrumentos financieros y permite que los profesionistas de las áreas económico-financieras y las apliquen esta normatividad adecuadamente. Las Normas de Información Financiera que se estudian en este curso son:</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>NIF C-1, Efectivo y equivalentes de efectivo</li>
              <li>NIF C-3, Cuentas por cobrar </li>
              <li>NIF C-2, Inversión en instrumentos financieros</li>
              <li>NIF C-10, Instrumentos financieros derivados y relaciones de cobertura</li>
              <li>NIF C-12, Instrumentos financieros con características de pasivo, de capital o de ambos</li>
              <li>NIF C-16, Deterioro en instrumentos financieros por cobrar</li>
              <li>NIF C-19, Instrumentos financieros por pagar</li>
              <li>NIF C-20, Instrumentos financieros por cobrar principal e interés</li>
              <li>NIF C-22, Criptomonedas </li>
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "NIF_IFD_180802",
    nombre: "NIF C-10 Instrumentos financieros derivados y relaciones de cobertura – Normas de Información Financiera 2022",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "6 Horas",
    puntosEducacionContinua: "0",
    curso: "NIF C-10 Instrumentos financieros derivados y relaciones de cobertura – Normas de Información Financiera 2022",
    linkTemario: "/",
    precioNoMiembro: 3600,
    precioMiembro: 2880,
    precioParticipanteRecurrente: 3240,
    imageCard: "2.jpg",
    pdf: "nif_ifd_180802.pdf",
    contenido: (
      <>
      <p>Los Instrumentos Financieros Derivados están en el centro de la innovación financiera, ya que muchos factores han impulsado su crecimiento, entre ellos, su regulación, la normatividad financiera internacional aplicable y la integración de la economía mundial.</p>
      <p>En este curso corto, se abordan los tipos de instrumentos financieros derivados más utilizados, sus características, condiciones para contratación, valuación y la normatividad referente a las coberturas contables. El estudio de la NIF C-10 abarca identificar los requisitos para el reconocimiento, valuación y revelación de la contabilidad de cobertura, la cual intenta reducir la volatilidad creada por los ajustes del valor de un instrumento financiero derivado.</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">NIF C-10 Instrumentos financieros derivados y relaciones de cobertura – Normas de Información Financiera 2022</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
            <div className="mt-5">
              <p>Este curso incluye información crítica sobre las características y condiciones para contratación de instrumentos financieros derivados, aspectos de su valuación y los requisitos de reconocimiento, valuación y revelación para la contabilidad de coberturas. Los temas son:</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>1.	Mercado de Derivados en México</li>
              <li>2.	Instrumentos Financieros Derivados</li>
                <ul>
                  <li>1.	Características y condiciones para contratación y valuación</li>
                  <ul>
                  <li>1.	Futuros</li>
                  <li>2.	Opciones</li>
                  <li>3.	Swaps</li>
                  </ul>
                </ul>
              <li>3.	Valuación de Contratos de Futuro</li>
                <ul>
                  <li>1.	Rendimiento</li>
                  <li>2.	Precio de ejercicio ITM, ATM y OTC</li>
                  <li>3.	Sensibilidades </li>
                  <li>4.	Paridad put-call</li>
                </ul>
              <li>4.	Estrategias de cobertura</li>
                <ul>
                  <li>1.	Estrategia y formaciones</li>
                </ul>
              <li>5.	Contabilidad de cobertura</li>
                <ul>
                  <li>1.	Razón de cobertura </li>
                  <li>2.	Eficacia</li>
                  <li>3.	Valuación</li>
                  <li>4.	Desreconocimiento</li>
                  <li>5.	Revelación</li>
                </ul>
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "NIF_AN_180803",
    nombre: "Adquisición de Negocios y otras inversiones: Su reconocimiento, valuación y revelación – Normas de Información Financiera 2022",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "10 Horas",
    puntosEducacionContinua: "0",
    curso: "Adquisición de Negocios y otras inversiones : Su reconocimiento, valuación y revelación",
    linkTemario: "/",
    precioNoMiembro: 4150,
    precioMiembro: 3320,
    precioParticipanteRecurrente: 3735,
    imageCard: "3.jpg",
    pdf: "nif_ang_180803.pdf",
    contenido: (
      <>
      <p>Este curso es parte del <a href="/programa/NIF_IF" >Programa de Actualización de Normas de Información Financiera</a> (NIFs) 2022, el cual es un plan de estudios integral que le brindará la capacitación detallada, el conocimiento y la orientación práctica en las NIFs.</p>
      <p>En este curso, se estudiarán las Normas de Información Financiera referentes a las inversiones permanentes, adquisiciones de negocio, consolidación y combinación de información financiera y los efectos de la conversión de monedas extranjeras en los estados financieros. (NIF B-7, B-8, B-15, C-7, C-13 y C-21).</p>
      <p>El profesional financiero identificará las normas aplicables para la parte inversionista o adquirente y para la parte que recibe la inversión, identificará el proceso de una adquisición de negocios, el reconocimiento de activos adquiridos, los pasivos asumidos y cualquier participación no controladora en el adquirente. Aprenderá a aplicar la NIF B-15 para las inversiones recibidas en moneda extranjera y aprenderá la consolidación y combinación de información financiera y las revelaciones necesarias.</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
        <p>Nota: Los cursos individuales comprados dentro del último año se pueden aplicar a la compra del Programa de Actualización de Normas de Información Financiera. <a href="/programa/NIF_IF" >Más Información aquí</a></p>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">Adquisición de Negocios y otras inversiones: Su reconocimiento, valuación y revelación – Normas de Información Financiera 2022</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
            <div className="mt-5">
              <p>Este curso aborda las Normas de Información Financiera que establecen los requisitos para el reconocimiento, valuación y revelación de las inversiones permanentes entre ellas las que califican como Adquisiciones de Negocios, la preparación de la información financiera consolidada o combinada y reconocimiento de los efectos de la conversión de moneda extranjera en la Información Financiera, incluyendo el estudio y casos prácticos de las siguientes NIFs:</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>NIF B-7, Adquisiciones de negocios</li>
              <li>NIF B-8, Estados financieros consolidados o combinados</li>
              <li>NIF B-15, Conversión de monedas extranjeras</li>
              <li>NIF C-7, Inversiones en asociadas, negocios conjuntos y otras inversiones permanentes</li>
              <li>NIF C-13, Partes relacionadas</li>
              <li>NIF C-21, Acuerdos con control conjunto</li>
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "NIF_AAR_180804",
    nombre: "Arrendamientos, Activos de larga duración y su deterioro: Reconocimiento, valuación y revelación – Normas de Información Financiera 2022",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "-",
    puntosEducacionContinua: "0",
    curso: "Arrendamientos, Activos de larga duración y su deterioro: Reconocimiento, valuación y revelación – Normas de Información Financiera 2022",
    linkTemario: "/",
    precioNoMiembro: 3735,
    precioMiembro: 2988,
    precioParticipanteRecurrente: 3362,
    imageCard: "4.jpg",
    contenido: (
      <>
      <p>Este curso es parte del <a href="/programa/NIF_IF" >Programa de Actualización de Normas de Información Financiera</a> (NIFs) 2022, el cual es un plan de estudios integral que le brindará la capacitación detallada, el conocimiento y la orientación práctica en las NIFs.</p>
      <p>En este curso, se estudiarán las Normas de Información Financiera referentes a los activos de larga duración, al reconocimiento del deterioro que afecta a este tipo de activos, y los arrendamientos. (NIF C-6, C-8, C-15, C-17, C-18, D-5 Y D-6).</p>
      <p>El profesional financiero analizará el impacto financiero de la aplicación de estas normas, como lo es el reconocimiento de los Arrendamientos bajo la NIF D-5, la cual requiere la afectación al balance vía aumento de los activos que se capitalizan, la contabilización de los pasivos y las aplicaciones a resultados, ya que los gastos de arrendamiento serán reemplazados por cargos por depreciación y gastos financieros (intereses). Aprenderá a aplicar las normas relativas a los activos de larga duración y la determinación de su deterioro.</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
        <p>Nota: Los cursos individuales comprados dentro del último año se pueden aplicar a la compra del Programa de Actualización de Normas de Información Financiera. <a href="/programa/NIF_IF" >Más Información aquí</a></p>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">Arrendamientos, Activos de larga duración y su deterioro: Reconocimiento, valuación y revelación – Normas de Información Financiera 2022</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
            <div className="mt-5">
              <p>Este curso aborda las Normas de Información Financiera relacionadas con las inversiones en activos de larga duración, el reconocimiento del deterioro y los arrendamientos, incluyendo el estudio y casos prácticos de las siguientes NIFs:</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>C-6	Activo fijo</li>
              <li>C-8	Intangibles</li>
              <li>C-15	Deterioro</li>
              <li>C-17	Propiedades de inversión</li>
              <li>C-18	Provisión por retiro de ac</li>
              <li>D-5	Arrendamientos</li>
              <li>D-6	Capitalización RIF</li>
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "NIF_INF_180805",
    nombre: "Preparación de Información Financiera para Empresas Públicas. – Normas de Información Financiera 2022",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "12 horas",
    puntosEducacionContinua: "0",
    curso: "Preparación de Información Financiera para Empresas Públicas. – Normas de Información Financiera 2022",
    linkTemario: "/",
    precioNoMiembro: 5400,
    precioMiembro: 4320,
    precioParticipanteRecurrente: 4860,
    imageCard: "5.png",
    contenido: (
      <>
      <p>Este curso es parte del <a href="/programa/NIF_IF" >Programa de Actualización de Normas de Información Financiera</a> (NIFs) 2022, el cual es un plan de estudios integral que le brindará la capacitación detallada, el conocimiento y la orientación práctica en las NIFs.</p>
      <p>En este curso, se estudiarán las Normas de Información Financiera útiles para la preparación de información financiera que las empresas públicas deben presentar para dar cumplimiento a los requerimientos específicos de revelación. Este curso se recomienda para todas las empresas que buscan robustecer la calidad de la información financiera: (NIF B-1,B-5, B-9, B-10, B-13, B-14, Y B-15).</p>
      <p>El estudio de este grupo de normas de información financieras brindará al profesionista, el conocimiento para gestionar la conversión de moneda extranjera, el reconocimiento de los efectos de una reexpresión y de cambios contables, así como tendrá la guía técnica para preparar información por segmentos de negocio, a fechas intermedias y revelar hechos posteriores.</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
        <p>Nota: Los cursos individuales comprados dentro del último año se pueden aplicar a la compra del Programa de Actualización de Normas de Información Financiera. <a href="/programa/NIF_IF" >Más Información aquí</a></p>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">Preparación de Información Financiera para Empresas Públicas. – Normas de Información Financiera 2022</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
            <div className="mt-5">
              <p>El profesional financiero aprenderá las normas de valuación, reconocimiento y revelación referentes a la conversión de moneda extranjera, a la reexpresión, al reconocimiento de cambios contables, así como la determinación de la utilidad por acción, la presentación de información por segmentos, la preparación de información a fechas intermedias y revelación de hechos posteriores.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>B-1	Cambios contables</li>
              <li>B-5	Segmentos</li>
              <li>B-9	Información a fechas intermedias</li>
              <li>B-10	Reexpresión</li>
              <li>B-13	Hechos posteriores</li>
              <li>B-14	UPA</li>
              <li>B-15	Conversión de monedas extranjera</li>
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "NIF_OBL_180806",
    nombre: "NIF D-3 Obligaciones Laborales: Reconocimiento, Valuación y Revelación – Normas de Información Financiera 2022",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "6 horas",
    puntosEducacionContinua: "0",
    curso: "Obligaciones Laborales: Reconocimiento, Valuación y Revelación – Normas de Información Financiera 2022",
    linkTemario: "/",
    precioNoMiembro: 2490,
    precioMiembro: 1992,
    precioParticipanteRecurrente: 2141,
    imageCard: "6.png",
    contenido: (
      <>
      <p>Este curso es parte del <a href="/programa/NIF_IF" >Programa de Actualización de Normas de Información Financiera</a> (NIFs) 2022, el cual es un plan de estudios integral que le brindará la capacitación detallada, el conocimiento y la orientación práctica en las NIFs.</p>
      <p>El estudio de la Norma de Información Financiera aplicable a las obligaciones laborales permitirá al profesionista financiero, la identificación de los tipos de planes de beneficios que las empresas ofrecen a sus colaboradores y la forma de reconocerlos, valuarlos y revelar los efectos en el pasivo por obligaciones laborales y la manera de gestionar adecuadamente este tipo de beneficios. Adicionalmente identificará los componentes de un estudio actuarial y como interpretar los resultados para su reconocimiento en la información financiera (NIF D-3).</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
        <p>Nota: Los cursos individuales comprados dentro del último año se pueden aplicar a la compra del Programa de Actualización de Normas de Información Financiera. <a href="/programa/NIF_IF" >Más Información aquí</a></p>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">NIF D-3 Obligaciones Laborales: Reconocimiento, Valuación y Revelación – Normas de Información Financiera 2022</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>Tipos de beneficios </li>
              <li>Planes de Beneficios</li>
              <li>Determinación del gasto por beneficios a empleados</li>
              <li>Determinación del pasivo por obligaciones laborales</li>
              <li>Formas de gestionar adecuadamente los planes de beneficios a empleados</li>
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "NIF_IMP_180806",
    nombre: "NIF D-4 Impuestos a la Utilidad (Actualización) Impuestos Diferidos y tratamientos fiscales inciertos",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "16 Horas",
    puntosEducacionContinua: "0",
    curso: "Impuestos a la Utilidad (Actualización) Impuestos Diferidos y tratamientos fiscales inciertos",
    linkTemario: "/",
    precioNoMiembro: 5600,
    precioMiembro: 4480,
    precioParticipanteRecurrente: 5040,
    imageCard: "7.jpg",
    contenido: (
      <>
      <p>Este curso es parte del <a href="/programa/NIF_IF" >Programa de Actualización de Normas de Información Financiera</a> (NIFs) 2022, el cual es un plan de estudios integral que le brindará la capacitación detallada, el conocimiento y la orientación práctica en las NIFs.</p>
      <p>El estudio y casos prácticos de la Norma de Información Financiera aplicable a los impuestos a la utilidad permitirá al profesionista financiero, la determinación de impuestos diferidos, el cálculo de la estimación para activo por impuesto diferido no recuperable y valuación de los tratamientos fiscales inciertos en el impuesto a la utilidad. Adicionalmente a través de casos prácticos aplicará análisis de sensibilidad y preparación de proyecciones financieras y fiscales que le permitan aplicar adecuadamente las normas de valuación, presentación y revelación contenidas en la NIF D-4.</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
        <p>Nota: Los cursos individuales comprados dentro del último año se pueden aplicar a la compra del Programa de Actualización de Normas de Información Financiera. <a href="/programa/NIF_IF" >Más Información aquí</a></p>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">NIF D-4  Impuestos a la Utilidad (Actualización) Impuestos Diferidos y tratamientos fiscales inciertos.</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>Impuesto Diferido </li>
              <li>Componentes de los activos y pasivos por impuesto diferido</li>
              <li>Estimación para activo por impuesto diferido no recuperable</li>
              <li>Análisis de sensibilidad</li>
              <li>Tratamientos fiscales inciertos en el impuesto a la utilidad</li>
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "NIF_PLI_180807",
    nombre: "Programa Integral de Normas de Información Financiera 2022",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "45 Horas",
    puntosEducacionContinua: "0",
    curso: "Programa Integral de Normas de Información Financiera 2022",
    linkTemario: "/",
    precioNoMiembro: 13500,
    precioMiembro: 10800,
    precioParticipanteRecurrente: 12150,
    imageCard: "8.png",
    contenido: (
      <>
      <p>Este <a href="/programa/NIF_IF" >Programa de Actualización de Normas de Información Financiera</a> (NIFs) 2022, presenta de manera integral la capacitación requerida para el estudio y aplicación de las  Normas de Información Financiera vigentes, abordando las normas a través de diferentes secciones o módulos que le permiten armar su propio plan de actualización.</p>
      <p>El método de estudio aplicado en este programa considera la revisión de las normas y el uso de ejemplos y casos prácticos, lo cual le permitirá al profesionista financiero, la actualización de sus conocimientos en materia normativa financiera y la formulación de estrategias de implementación, adecuación de sus procesos operativos referentes a la preparación de la información financiera fortaleciendo su rol dentro de la organización.</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
        <p>Nota: Los cursos individuales comprados dentro del último año se pueden aplicar a la compra del Programa de Actualización de Normas de Información Financiera. <a href="/programa/NIF_IF" >Más Información aquí</a></p>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">NIF D-4  Impuestos a la Utilidad (Actualización) Impuestos Diferidos y tratamientos fiscales inciertos.</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
            
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <div className="mt-5">
              <p>Presentación de Información Financiera(Estados Financieros)</p>
            </div>
            <List type="unstyled">
              <li>B-1	Cambios contables</li>
              <li>B-2	Flujos de efectivo</li>
              <li>B-3	Resultado integral</li>
              <li>B-4	Cambios en el capital contable</li>
              <li>B-6	Situación financiera</li>
              <li>B-17	Valor razonable</li>
              <li>C-11	Capital contable</li>
            </List>
          </Col>
        </Row>
        <Row>
          <Col className="mt-1" md="12">
            <div className="mt-1">
              <p>Gestión de Activos</p>
            </div>
            <List type="unstyled">
              <li>C-1	Efectivo</li>
              <li>C-2	Instrumentos Financieros negociables</li>
              <li>C-3	Cuentas por cobrar</li>
              <li>C-4	Inventarios</li>
              <li>C-6	Activo fijo</li>
              <li>C-8	Intangibles</li>
              <li>C-10	Instrumentos Financieros Derivados</li>
              <li>C-12	Instrumentos Financieros combinados</li>
              <li>C-15	Deterioro</li>
              <li>C-16	Deterioro de Instrumentos Financieros por cobrar</li>
              <li>C-17	Propiedades de inversión</li>
              <li>C-19	Instrumentos Financieros por Pagar</li>
              <li>C-20	Instrumentos Financieros para Cobrar Principal e Intereses</li>
            </List>
          </Col>
        </Row>
        <Row>
          <Col className="mt-1" md="12">
            <div className="mt-1">
              <p>Gestión de Pasivos</p>
            </div>
            <List type="unstyled">
              <li>C-9	Provisiones, contingencias y compromisos</li>
              <li>B-12	Compensación activos y pasivos</li>
              <li>C-18	Obligaciones asociadas con el retiro de propiedades, planta y equipo</li>
              <li>D-3	Beneficios a empleados</li>
            </List>
          </Col>
        </Row>
        <Row>
          <Col className="mt-1" md="12">
            <div className="mt-1">
              <p>Normas Específicas para la determinación de Resultados</p>
            </div>
            <List type="unstyled">
              <li>D-1	Ingresos</li>
              <li>D-2	Costos de ingresos</li>
              <li>D-4	Impuestos a la utilidad</li>
              <li>D-5	Arrendamientos</li>
              <li>D-6	Capitalización del Resultado Integral de Financiamiento</li>
              <li>D-8	Pagos basados en acciones</li>
            </List>
          </Col>
        </Row>
        <Row>
          <Col className="mt-1" md="12">
            <div className="mt-1">
              <p>Preparación de Información Financiera para Empresas Públicas</p>
            </div>
            <List type="unstyled">
              <li>B-1	Cambios contables</li>
              <li>B-5	Segmentos</li>
              <li>B-9	Información a fechas intermedias</li>
              <li>B-10	Reexpresión</li>
              <li>B-13	Hechos posteriores</li>
              <li>B-14	Utilidad por Acción</li>
              <li>B-15	Conversión de monedas extranjera</li>
            </List>
          </Col>
        </Row>
        <Row>
          <Col className="mt-1" md="12">
            <div className="mt-1">
              <p>Estados financieros consolidados</p>
            </div>
            <List type="unstyled">
              <li>B-7	Adquisiciones de negocios</li>
              <li>B-8	Consolidación de Estados Financieros </li>
              <li>C-7	Método de Participación</li>
              <li>C-13	Partes relacionadas</li>
              <li>C-21	Negocios conjuntos</li>
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "CG_SMC_180804",
    nombre: "Contabilidad de Costos, Gestión y Control",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "20 Horas",
    puntosEducacionContinua: "0",
    curso: "Contabilidad de Costos, Gestión y Control",
    linkTemario: "/",
    precioNoMiembro: 6000,
    precioMiembro: 4800,
    precioParticipanteRecurrente: 5400,
    imageCard: "9.jpg",
    contenido: (
      <>
      <p>Este curso integra el estudio de los métodos de costeo tradicional como costeo por procesos, costeo conjunto, costeo estándar y otros, aplicables tanto a empresas productivas como de servicios.  Adicional, proporciona las bases para la implementación de costeo basado en actividades (Costeo ABC), lo que le permitirá al profesionista identificar y definir el sistema de costeo más apropiado para su organización  y a través de casos prácticos aplicará metodologías de apoyo como son cadena de valor, teoría de las restricciones, costos de calidad y otros conceptos, con la finalidad de robustecer los procesos de reconocimiento, agrupación, control y monitoreo de los costos.</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">Contabilidad de Costos, Gestión y Control.</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>Conceptos fundamentales de la Contabilidad de Costos </li>
              <li>Pensamiento sistémico aplicable al diseño de procesos</li>
              <li>Cadena de Valor para análisis de procesos</li>
              <li>Costeo Por procesos</li>
              <li>Costeo conjunto</li>
              <li>Costeo estándar y el análisis de las desviaciones</li>
              <li>Costeo ABC</li>
              <li>Costos de Calidad</li>
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "CG_USG_180805",
    nombre: "Aplicación práctica de los principales US GAAPS",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "20 Horas",
    puntosEducacionContinua: "0",
    curso: "Aplicación práctica de los principales US GAAPS",
    linkTemario: "/",
    precioNoMiembro: 6000,
    precioMiembro: 4800,
    precioParticipanteRecurrente: 5400,
    imageCard: "10.png",
    contenido: (
      <>
        <p>El profesionista a través de la metodología educativa que incluye casos prácticos desarrollará los conocimientos y la aplicación práctica de los Principios Generalmente Aceptados en Estados Unidos de América. (US GAAPs).</p>
        <p>Este curso ha sido diseñado para proporcionar el conocimiento de los principales conceptos y metodologías de valuación aplicables a diferentes tipos de transacciones como son: el reconocimiento de ingresos, Instrumentos financieros y su deterioro, Consolidación de estados financieros, impuestos diferidos y su tratamiento, y la conversión de moneda extranjera, todo lo anterior, de conformidad con la normatividad de US GAAPs  vigente.</p>
        <p>Adicional se incluye información de las obligaciones de reporteo que las empresas subsidiarias en el extranjero tienen conforme a las reglas de revelación de los US GAAPs.</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">Aplicación práctica de los principales US GAAPS</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>Tema I</li>
                <ul>
                  <li>Introducción – FASB </li>
                  <li>Codification ASC – Taxonomy ASC </li>
                </ul>
              <li>Tema II: Técnicas de valuación aplicables a los US GAAPs </li>
                <ul>
                  <li>Variable Interest Model</li>
                  <li>Multiple regression model </li>
                  <li>More likely than not approach </li>
                </ul>
              <li>Tema III: Reconocimiento de Ingresos ASC 606 </li>
                <ul>
                  <li>5 steps Model </li>
                </ul>
              <li>Tema IV: Instrumentos Financieros (ASU relativas a Pérdida Crediticia y Derivados) </li>
                <ul>
                  <li>Expected Credit Losses ASC 326 </li>
                </ul>
              <li>Tema V: Consolidación Financiera ASC 805 </li>
                <ul>
                  <li>Acquisition method </li>
                </ul>
              <li>Tema VI: Impuestos a la Utilidad (Causado y Diferido) ASC 740 </li>
                <ul>
                  <li>Valuation Allowance </li>
                  <li>Carryback & Carryforward  </li>
                  <li>FIN 48 Accounting for Uncertainty in Income Taxes </li>
                </ul>
              <li>Tema VII: Conversión de Moneda Extranjera ASC 830  </li>
                <ul>
                  <li>Current trate translation method  </li>
                  <li>Temporal translation method </li>
                  <li>Monetary-non monetary translation method </li>
                </ul>               
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "CG_USG_180806",
    nombre: "US GAAP: Conversión de Moneda Extranjera ASC 830 (Antes conocido como FAS 52) ",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "6 Horas",
    puntosEducacionContinua: "0",
    curso: "US GAAP: Conversión de Moneda Extranjera ASC 830 (Antes conocido como FAS 52) ",
    linkTemario: "/",
    precioNoMiembro: 2490,
    precioMiembro: 1992,
    precioParticipanteRecurrente: 2241,
    imageCard: "1.jpg",
    contenido: (
      <>
        <p>Este curso corto ha sido diseñado para que de una manera práctica el profesionista aplique las reglas y métodos de valuación, presentación y revelación contenidas en el tópico 830 de los US GAAPs relacionadas con la conversión de moneda a extranjera. El participante identificará el método de conversión aplicable a la organización según los tipos de moneda con la que realicen sus transacciones, comprobará el tratamiento y monitoreo que debe darse a los efectos de la conversión de moneda incluso cuando la entidad inversionista extranjera decida retirar su inversión.</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">Aplicación práctica de los principales US GAAPS</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>Tema VII: Conversión de Moneda Extranjera ASC 830  </li>
                <ul>
                  <li>Indicadores para la conversión de moneda extranjera </li>
                  <li>Operaciones separables e independientes</li>
                  <li>Método de tipo de cambio de cierre (Current rate translation method) </li>
                  <li>Método Temporal (Temporal translation method) </li>
                  <li>Método monetario y no monetario (Monetary-non monetary translation method) </li>
                </ul>               
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "CG_TRE_180807",
    nombre: "Gestión de Tesorería y Administración del Efectivo",
    formato: "Online",
    campoEstudio: "Finanzas",
    nivel: "Intermedio",
    duracion: "16 Horas",
    puntosEducacionContinua: "0",
    curso: "Gestión de Tesorería y Administración del Efectivo",
    linkTemario: "/",
    precioNoMiembro: 5600,
    precioMiembro: 4480,
    precioParticipanteRecurrente: 5040,
    imageCard: "2.jpg",
    contenido: (
      <>
        <p>La rápida y adecuada gestión de la tesorería es un pilar fundamental en la vida y continuidad de los negocios, por eso el profesionista dedicado a esta actividad requiere del uso y manejo de técnicas financieras que le permitan reaccionar ante las necesidades de la organización, tener una visión de corto plazo para la administración eficiente del flujo de efectivo, identificar el mejor momento para la inversión a corto o largo plazo y la cuantificación del financiamiento requerido. En este curso el profesionista identificará las palancas de valor que debe considerar para tener un ciclo de conversión de efectivo óptimo. El curso se basa en casos prácticos que proporcionan estrategias tácticas para la gestión de la tesorería.</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">Aplicación práctica de los principales US GAAPS</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>Ciclo de conversión del efectivo</li>
              <li>Palancas de valor</li>
              <li>Administración del efectivo</li>
              <li>Inversión de efectivo a corto o largo plazo</li>
              <li>Financiamiento externo requerido</li>
              <li>Modelos de gestión de la tesorería moderna</li>
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "CG_AFI_180808",
    nombre: "Análisis Financiero, Tableros de control y Reportes de desempeño",
    formato: "Online",
    campoEstudio: "Finanzas",
    nivel: "Intermedio",
    duracion: "18 Horas",
    puntosEducacionContinua: "0",
    curso: "Análisis Financiero, Tableros de control y Reportes de desempeño",
    linkTemario: "/",
    precioNoMiembro: 7200,
    precioMiembro: 5760,
    precioParticipanteRecurrente: 6480,
    imageCard: "3.jpg",
    contenido: (
      <>
        <p>El análisis financiero permite conocer la salud financiera de las entidades y la definición de acciones rápidas que permitan redireccionar el rumbo del negocio. Un profesionista dedicado al análisis financiero desarrolla competencias que las organizaciones requieren como son visión crítica, enfoque a resultados, etc.</p>
        <p>Este curso abarca el estudio y práctica de las principales razones financieras, técnicas de administración del efectivo, métricas de desempeño y valuación que podrán implementarse rápidamente para la construcción de tableros de control y creación de reportes.</p>
        <p>El curso se basa en casos prácticos de análisis, interpretación y medición del desempeño financiero.</p>
      <p>Se incluyen ejercicios ilustrativos con soluciones.</p>
      </>
    ),
    footer: (
      <>
      </>
    ),
    temario: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">Aplicación práctica de los principales US GAAPS</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>1. ESTADOS FINANCIEROS PROFORMA </li>
                <ul>
                  <li>Enfoque Porcentaje de Ventas </li>
                  <li>Tasa Interna de Crecimiento </li>
                  <li>Tasa de Crecimiento Sostenible </li>
                </ul>
              <li>2. ANALISIS DE SENSIBILIDAD </li>
              <li>3. TENDENCIAS Y PORCENTAJES INTEGRALES  </li>
                <ul>
                <li>Análisis Cuantitativo (Análisis Vertical, Análisis Horizontal y Tendencias)</li>
                <li>Estacionalidad </li>
                <li>Tasa de Crecimiento Compuesto (CARG) </li>
                <li>Análisis Financiero del Estado de Resultados</li>
                <li>Análisis Financiero del Balance General </li>
                <li>Variación en ventas y contribución marginal </li>
                </ul>
              <li>4. RAZONES FINANCIERAS</li>
                <ul>
                  <li>Liquidez </li>
                  <li>Apalancamiento </li>
                  <li>Solvencia </li>
                  <li>Rentabilidad  </li>
                  <li>Utilidad por acción  </li>
                  <li>ROE, ROA </li>
                  <li>WACC  </li>
                  <li>Calculo del EVA </li>
                </ul>
              <li>5. FLUJO DE EFECTIVO</li>
                <ul>
                  <li>Ciclo de Conversión de Efectivo  </li>
                  <li>Administración de Efectivo   </li>
                </ul>
              <li>6. FINANCIAMIENTO </li>
                <ul>
                  <li>Financiamiento Externo Requerido </li>
                </ul>
              <li>7. INDICADORES Y TABLERO DE CONTROL   </li>
                <ul>
                  <li>Diseño de Indicadores   </li>
                  <li>Acciones de monitoreo  </li>
                  <li>Diseño de tablero de control  </li>
                  <li>Diseño de Reportes  </li>
                </ul>               
            </List>
          </Col>
        </Row>
      </>
    )
  },
]

export const programas = [
  {
    key: "NIF_IF",
    nombre: "Instrumentos Financieros - Normas de Información Financiera 2022",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "12 Horas",
    puntosEducacionContinua: "0",
    curso: "Instrumentos Financieros",
    mesAnio: "Febrero 2022",
    mesDiaInicio: "Febrero 1",
    mesDiaTermino: "Febrero 10",
    diasSemanana: "Martes y Jueves",
    horario: "De 19 a 22 Hrs",
    fechaLimiteInscripcion: "30/01/2022",
    content: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">Instrumentos Financieros - Normas de Información Financiera 2022</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
            <div className="mt-5">
              <p>Este curso aborda las Normas de Información Financiera que establecen los requisitos para el reconocimiento, valuación y revelación de los instrumentos financieros y permite que los profesionistas de las áreas económico-financieras y las apliquen esta normatividad adecuadamente. Las Normas de Información Financiera que se estudian en este curso son:</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5" md="12">
            <List type="unstyled">
              <li>NIF B-17, Determinación del valor razonable</li>
              <li>NIF B-12, Compensación de activos financieros y pasivos financieros</li>
              <li>NIF C-1, Efectivo y equivalentes de efectivo</li>
              <li>NIF C-3, Cuentas por cobrar </li>
              <li>NIF C-2, Inversión en instrumentos financieros</li>
              <li>NIF C-10, Instrumentos financieros derivados y relaciones de cobertura</li>
              <li>NIF C-11, Capital contable</li>
              <li>NIF C-12, Instrumentos financieros con características de pasivo, de capital o de ambos</li>
              <li>NIF C-14, Transferencia y baja de instrumentos financieros </li>
              <li>NIF C-16, Deterioro en instrumentos financieros por cobrar</li>
              <li>NIF C-19, Instrumentos financieros por pagar</li>
              <li>NIF C-20, Instrumentos financieros por cobrar principal e interés</li>
              <li>NIF C-22, Criptomonedas </li>
            </List>
          </Col>
        </Row>
      </>
    )
  },
  {
    key: "NIF_IFD_180802",
    nombre: "NIF C-10 Instrumentos financieros derivados y relaciones de cobertura - Normas de Información Financiera 2022",
    formato: "Online",
    campoEstudio: "Contabilidad",
    nivel: "Intermedio",
    duracion: "6 Horas",
    puntosEducacionContinua: "0",
    curso: "NIF C-10 Instrumentos financieros derivados y relaciones de cobertura - Normas de Información Financiera 2022",
    linkTemario: "/",
    content: (
      <>
        <Row>
          <Col className="mr-auto" md="12">
            <div className="text-center">
              <h1 className="title">Instrumentos Financieros - Normas de Información Financiera 2022</h1>
              <h2 className="title">Temario Detallado</h2>
            </div>
            <div className="mt-5">
              <p>Los Instrumentos Financieros Derivados están en el centro de la innovación financiera, ya que muchos factores han impulsado su crecimiento, entre ellos, su regulación, la normatividad financiera internacional aplicable y la integración de la economía mundial.</p>
            <p>En este curso corto, se abordan los tipos de instrumentos financieros derivados más utilizados, sus características, condiciones para contratación, valuación y la normatividad referente a las coberturas contables. El estudio de la NIF C-10 abarca identificar los requisitos para el reconocimiento, valuación y revelación de la contabilidad de cobertura, la cual intenta reducir la volatilidad creada por los ajustes del valor de un instrumento financiero derivado.</p>
            <p>Se incluyen ejercicios ilustrativos con soluciones. </p>
            </div>
          </Col>
        </Row>
 \     </>
    )
  }
]
    
export const categorias = ["Categoria 1", "Categoria 2", "Categoria 3"];

export const suscripciones = [
    {
      id: "noMiembro",
      name: "Basic",
      price: "0",
    perks: [
          "Invitaciones a Webinars",
          "Puedes acceder a los cursos realizando el pago individual de cada uno",
          "Ideal si solo deseas un curso en particular",
      ],
    },
    {
      id: "suscripcionRecurrente",
      name: "Pro",
      price: "500",
      perks: [
        "Incluye los beneficios de la suscripción Basic",
        "Incluye 20% de descuento al adquirir cualquier curso",
        "Incluye 5% de descuento adicional si registras un grupo de 6 o más personas"
      ],
    },
    {
      id: "suscripcionMiembro",
      name: "Business",
      price: "1000",
      perks: [
        "Todos los beneficios del plan Basic",
        "Enfocado a grupos de 3 o mas personas",  
        "Catalogo de Cursos sin costo Adicional",
        "Incluye 10% de descuento al adquirir cualquier curso",
        "Incluye 10% de descuento adicional si registras un grupo de 3 o más personas"
      ],
    },
  ];