import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormAlert from './FormAlert';
import Form from "react-bootstrap/Form";
import FormField from "./FormField";
import { useForm, Controller } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { createCursoPorFecha, updateCursoPorFecha, useCursoPorFecha } from '../util/db';
import { cursos } from '../util/datos'
import Select from 'react-select'
import Calendario from './Calendario';
import { Col, Row } from 'reactstrap';


function CursoModal(props) {

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [opciones, setOpciones] = useState(null);
  const [calendarValue, setCalendarValue] = useState("");
  const [calendarValueFin, setCalendarValueFin] = useState("");

  useEffect(() => {
    if (cursos) { 
      const temp = cursos.map(function (curso) {
        return { value: {key: curso.key, nombre: curso.nombre}, label: curso.nombre };
        });
        setOpciones(temp)
      }
    }, []);

    const { register, handleSubmit, control, errors } = useForm();

    const { data: itemData, status: itemStatus } = useCursoPorFecha(props.id);

    if (props.id && itemStatus !== "success") {
    return null;
    }

    const handleCalendarChange = (element, currentValue) => {
        setCalendarValue(currentValue);
  };
  
  const handleCalendarChangeFin = (element, currentValue) => {
        setCalendarValueFin(currentValue);
    };
    
  const onSubmit = (data) => {

    setPending(true);
      const query = props.id
        ? updateCursoPorFecha(props.id, { nombre: data.curso.nombre, key: data.curso.key, fechaInicio: calendarValue, diasCurso: data.diasCurso, horario: data.horario, fechaFin: calendarValueFin })
      : createCursoPorFecha( {nombre: data.curso.nombre, key: data.curso.key, fechaInicio: calendarValue, diasCurso: data.diasCurso, horario: data.horario, fechaFin: calendarValueFin} ); 

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone(true);
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <Modal show={true} centered={true} animation={false} onHide={props.onDone}>
      <Modal.Header closeButton={true}>
        {props.id && <>Actualizar</>}
        {!props.id && <>Crear</>}
        {` `}Inicio de Curso
      </Modal.Header>
      <Modal.Body>
        {formAlert && (
          <FormAlert type={formAlert.type} message={formAlert.message} />
        )}

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formCurso">
            <Controller
                control={control}
                defaultValue=""
                name="curso"
                render={({ onChange, value, name, ref }) => (
                  <Select
                    inputRef={ref}
                    className="react-select react-select-primary mb-2"
                    placeholder="Selecciona Curso"
                    /* classNamePrefix="react-select" */
                    options={opciones}
                    onChange={(val) => onChange(val.value)}
                  />
                )}
            />
            <FormField
                size="md"
                name="diasCurso"
                type="text"
                label="Dias de Imparticion"
                placeholder="Dias de la semana en que se dara el curso"
                defaultValue={itemData && itemData.diasCurso}
                error={errors.diasCurso}
                autoFocus={true}
                inputRef={register({
                  required: "Debes de ingresar al menos un dia de la semana",
                })}
            />
            <FormField
                size="md"
                name="horario"
                type="text"
                label="Horario de Imparticion"
                placeholder="Horario en el que se impartira el curso"
                defaultValue={itemData && itemData.horario}
                error={errors.horario}
                autoFocus={true}
                inputRef={register({
                  required: "Debes de ingresar el horario del curso",
                })}
              />
          </Form.Group>
          <Row>
              <Col className="text-center" md="12">
                  <span className="text-info">Selecciona la fecha y hora de inicio (Este sera tambien la fecha limite de inscripcion): </span>
                  <Calendario
                  options={{
                    value: calendarValue,
                    onchange: handleCalendarChange,
                    readonly: false,
                    placeholder: "Selecciona una fecha",
                    validRange: [ '2020-02-01', '2030-12-31' ],
                    /* time:true,
                    format:'DD/MM/YYYY HH24:MI', */
                  }}
                />  
              </Col>
            </Row>
          <Row>
              <Col className="text-center" md="12">
                  <span className="text-info">Selecciona la fecha y hora de termino del curso: </span>
                  <br />
                  <Calendario
                  options={{
                    value: calendarValueFin,
                    onchange: handleCalendarChangeFin,
                    readonly: false,
                    placeholder: "Selecciona una fecha",
                    validRange: [ '2020-02-01', '2030-12-31' ],
                    format:'DD/MM/YYYY',
                  }}
                />  
              </Col>
            </Row>  
          
          <Button size="lg" variant="primary" type="submit" disabled={pending}>
            <span>Guardar</span>

            {pending && (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden={true}
                className="ml-2"
              >
                <span className="sr-only">Cargando...</span>
              </Spinner>
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default CursoModal