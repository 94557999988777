import React from "react";
import "./BackgroundVideo.scss";

function BackgroundVideo(props) {
  return (
    <>
      <video
          autoPlay="autoplay"
          loop="loop"
          muted="muted"
          playsInline="playsinline"
        >
        <source
          src={props.video.default}
          type="video/mp4"
        />
      </video>
    </>  
  );
}

export default BackgroundVideo;
