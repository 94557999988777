import React from "react";
import Meta from "../components/Meta";
import ProgramaSection from "../components/ProgramaSection";
import { useRouter } from "../util/router";

function ProgramaPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Curso" />
      <ProgramaSection
        bg="white"
        textColor="black"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </>
  );
}

export default ProgramaPage;