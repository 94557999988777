import React from 'react';
import { NavItem, NavLink } from 'reactstrap';

const RedesSociales = () => {
    return (
        <>
          <NavItem>
                <NavLink
                    href="https://twitter.com/"
                    target="_blank"
                >
                    <i className="fab fa-twitter" />
                    <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    href="https://www.facebook.com/"
                    target="_blank"
                >
                    <i className="fab fa-facebook-square" />
                    <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    href="https://www.instagram.com/"
                    target="_blank"
                >
                    <i className="fab fa-instagram" />
                    <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
            </NavItem>  
        </>
    );
}

export default RedesSociales;
