import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
//import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";
/* import Mision from "../components/Mision";
import Vision from "../components/Vision"; */

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="QUIENES SOMOS"
        subtitle="Expertos en diferentes áreas de negocios, como Riesgos, Legal, Finanzas, Contabilidad, Cumplimiento, Tecnología, etc. Todos que estamos dispuestos a trabajar para ti y que logres tus objetivos."
      />
      <HeroSection2
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="VISION"
        subtitle="Crear soluciones de negocio que sostengan el éxito de nuestros clientes."
      />
      <HeroSection2
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="MISION"
        subtitle="En Intellectus-eXD trabajamos para potencializar la capacidad emprendedora y profesional de nuestros clientes, ampliando las posibilidades de desarrollo extraordinario y sustentable en los ámbitos de negocio a través de nuestras soluciones financieras, servicios y con el grupo de expertos que colaboran para generar valor y promover nuestra contribución a la sociedad."
      />
      
      {/* <StatsSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
      /> */}
      <CtaSection
        bg="white"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="¿Listo para comenzar?"
        subtitle=""
        buttonText="Iniciar"
        buttonColor="danger"
        buttonPath="/pricing"
      />
    </>
  );
}

export default AboutPage;
