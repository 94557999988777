import React from "react";
import "./BackgroundImage.scss";
import videoHeader from '../assets/video/video.mp4'


function BackgroundImage(props) {
  return (
    <video autoPlay loop muted
          style={{
            position: 'absolute',
            width: '100%',
             left: '50%',
             top: '50%',
            height: '100%',
             objectFit: 'cover',
             transform: 'translate(-50%, -50%) ',
             //zIndex: '-1',
          }}>
        <source src={videoHeader} type="video/mp4" />
      </video>
  );
}

export default BackgroundImage;
