import React from 'react'
// ReactJS plugin for a nice carousel
import Slick from "react-slick";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardImg,
  CardText,
} from "reactstrap";
import { Link } from 'react-router-dom';
import { cursos } from '../util/datos'

// custom previous button for the slick component
const PrevButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-prev slick-arrow"
      color="primary"
      aria-label="Previous"
      type="button"
      onClick={props.onClick}
    >
      <i className="tim-icons icon-minimal-left" />
    </Button>
  );
};
// custom next button for the slick component
const NextButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-next slick-arrow"
      color="primary"
      aria-label="Next"
      type="button"
    >
      <i className="tim-icons icon-minimal-right" onClick={props.onClick} />
    </Button>
  );
};


let slickSettings = {
  dots: false,
  infinite: true,
  centerMode: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  className: "center slider",
  slide: "section",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};

function SlickerCursos2() {
  return (
    
        <div className="testimonials-4">
          <header
                className="SectionHeader"
            >
                <h2
                style={{color: '#000000'}}
                className = "font-weight-bold mb-4 text-center container">
                Nuestros Cursos
                </h2>
            </header>
          <Container fluid>
            <Row>
              <Col md="12">
                <Slick {...slickSettings}>
                  {cursos.map((curso, index) => (
                    <div key={index}>
                      <Card style={{backgroundColor:'#8C0019'}}>
                        <CardImg className="ml-auto mr-auto" top src={
                                    require("../assets/img/cursos/"+ curso.imageCard +"").default
                                  } alt="..."></CardImg>
                        <CardBody>
                            {/* <CardTitle>Card Title</CardTitle> */}
                            <CardText className="text-center">{curso.nombre}</CardText>
                            <CardText className="text-center"><Link to={"/curso/" + curso.key + ""}><Button color="danger">Ir a Curso</Button></Link></CardText>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Slick>
              </Col>
            </Row> 
          </Container>
        </div>
        
  )
}

export default SlickerCursos2