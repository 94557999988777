import React from "react";
import LongContent from "./LongContent";

function CookiesPolicy(props) {
  return (
    <LongContent>
      <h1>Politica de Cookies</h1>
      <p>
        De acuerdo con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, esta página web le informa, en esta sección, sobre la política de recogida y tratamiento de cookies..</p>
      <h2>¿QUÉ SON LAS COOKIES?</h2>
      <p>
        Una cookie es un archivo que se descarga en su computadora o dispositivo móvil al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario. 
      </p>
      <h2>¿QUÉ TIPOS DE COOKIES UTILIZA ESTA PÁGINA WEB?</h2>
      <p>
        Esta página web utiliza los siguientes tipos de cookies:
      </p>
      <p>Cookies de análisis: Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.
      </p>
      <p>Cookies técnicas: Son aquellas que permiten al usuario la navegación a través del área restringida y la utilización de sus diferentes funciones, como por ejemplo, llevar a cambio el proceso de compra de un artículo.</p>
      <p>Cookies de personalización: Son aquellas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma o el tipo de navegador a través del cual se conecta al servicio.</p>
      <h2>DESACTIVAR LAS COOKIES.</h2>
      <p>
        Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.</p>
      <p>En la mayoría de los navegadores web se ofrece la posibilidad de permitir, bloquear o eliminar las cookies instaladas en su equipo.</p>
      <p>A continuación puede acceder a la configuración de los navegadores webs más frecuentes para aceptar, instalar o desactivar las cookies:</p>
      <ol>
        <li><a href='https://support.google.com/chrome/answer/95647?hl=es'>Configurar cookies en Google Chrome</a></li>
        <li><a href='https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d'>Configurar cookies en Microsoft Internet Explorer</a></li>
        <li><a href='https://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia'>Configurar cookies en Mozilla Firefox</a></li>
        <li><a href='https://support.apple.com/es-es/HT201265'>Configurar cookies en Safari (Apple)</a></li>
      </ol>
      <h2>COOKIES DE TERCEROS</h2>
      <p>Esta página web utiliza servicios de terceros para recopilar información con fines estadísticos y de uso de la web.</p>
      <p>En concreto, usamos los servicios de Google Adsense y de Google Analytics para nuestras estadísticas y publicidad. Algunas cookies son esenciales para el funcionamiento del sitio.</p>
      <p>Nuestro sitio incluye otras funcionalidades proporcionadas por terceros. Usted puede fácilmente compartir el contenido en redes sociales como Facebook, Twitter o Google +, con los botones que hemos incluido a tal efecto.</p>
      <h2>ADVERTENCIA SOBRE ELIMINAR COOKIES</h2>
      <p>Usted puede eliminar y bloquear todas las cookies de este sitio, pero parte del sitio no funcionará o la calidad de la página web puede verse afectada.</p>
      <p>Si tiene cualquier duda acerca de nuestra política de cookies, puede contactar con esta página web a través de nuestros canales de Contacto.</p>
    </LongContent>
  );
}

export default CookiesPolicy;
