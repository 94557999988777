import React from "react";
import { useAuth } from "../util/auth";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

const AuthenticationButton = () => {
  const auth = useAuth();

  return auth.user ? <LogoutButton /> : <LoginButton />;
};

export default AuthenticationButton;
