import React from "react";
import Meta from "../components/Meta";
import TemarioSection from "../components/TemarioSection";
import { useRouter } from "../util/router";

function TemarioPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Temario" />
      <TemarioSection
        bg="white"
        textColor="black"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </>
  );
}

export default TemarioPage;