import React, { useState } from 'react';
import FormAlert from "./FormAlert";
import { useDescuentos, updateDescuento, deleteDescuento } from '../util/db';
import { Button, Card, Spinner } from 'react-bootstrap';
import ListGroup from "react-bootstrap/ListGroup";
import EditDescuentoModal from './EditDescuentoModal'

function DashboardCodigos() {

    const [alert, setAlert] = useState(null);
    const [creatingDescuento, setCreatingDescuento] = useState(false);
    const [updatingDescuento, setUpdatingDescuento] = useState(null);

    const {
    data: datos,
    status: datosStatus,
    error: datosError,
    } = useDescuentos();
    
    const datosAreEmpty = !datos || datos.length === 0;

    const handleActivation = (descuento) => {
    
        updateDescuento(descuento.id, { activo: !descuento.activo });
        console.log(descuento.activo);
        if ( descuento.activo === true )
            setAlert({message: "Se activo correctamente el codigo de Promocion"})
        else setAlert({message: "Se desactivo correctamente el codigo de Promocion"});
    
  };

    return (
       <>
      {datosError && (
        <div className="mb-3">
          <FormAlert type="error" message={datosError.message} />
        </div>
      )}
      
      {alert && (
        <div className="mb-3">
          <FormAlert type="success" message={alert.message} dismissible onClose={() => setAlert(null)}/>
        </div>
      )}

      <div className="DashboardItems__card-items-wrapper">
        <Card className="card-items" style={{backgroundColor: "white", color: "black"}}>
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center" style={{ backgroundColor: "white", color: "black" }}
          >
            Codigos Promocionales
            <Button
              variant="info"
              size="md"
              onClick={() => setCreatingDescuento(true)}
            >
              Nuevo Codigo
            </Button>
          </Card.Header>

          {(datosStatus === "loading" || datosAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {datosStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Cargando...</span>
                </Spinner>
              )}

              {datosStatus !== "loading" && datosAreEmpty && (
                <>No hay ningun codigo registrado</>
              )}
            </div>
          )}

          {datosStatus !== "loading" && datos && datos.length > 0 && (
            <ListGroup variant="flush">
              {datos.map((descuento, index) => (
                <ListGroup.Item
                  key={index}
                  style={{backgroundColor: (descuento.activo ? "lightgreen" : "gold")}}
                  className={
                    'd-flex justify-content-between align-items-center'
                  }
                >
                      {descuento.codigo} - {descuento.porcentajeDescuento}% - {descuento.activo === true ? "Activo" : "Inactivo"}
                  <div>
                    <Button
                      variant="link"
                      aria-label="star"
                      onClick={() => handleActivation(descuento)}
                      className={
                        `action` + (descuento.activo ? ` star-featured` : "")
                      }
                    >
                      <i className="fas fa-star" />
                    </Button>
                    <Button
                      variant="link"
                      aria-label="update"
                      onClick={() => setUpdatingDescuento(descuento.id)}
                      className="action"
                    >
                      <i className="fas fa-edit" />
                    </Button>
                    <Button
                      variant="link"
                      aria-label="delete"
                      onClick={() => deleteDescuento(descuento.id)}
                      className="action"
                    >
                      <i className="fas fa-trash" />
                    </Button>
                    
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Card>
      </div>
    
        {creatingDescuento && <EditDescuentoModal onDone={(success) => {
                setCreatingDescuento(false);
                success === true && setAlert({message: "Se creo correctamente el codigo de Promocion"})
            }} />}

        {updatingDescuento && (
            <EditDescuentoModal
                id={updatingDescuento}
                onDone={(success) => {
                    setUpdatingDescuento(null);
                    success === true && setAlert({message: "Se actualizo correctamente el codigo de Promocion"})
                }}
            />
      )}
      
    </>
    );
}

export default DashboardCodigos;
