import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  getDocs,
  limit,
} from "firebase/firestore";
import { firebaseApp } from "./firebase";
import moment from 'moment';

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Firestore query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

export function useUsers() {
  return useQuery(
    ["users"],
    createQuery(() =>
      query(
        collection(db, "users"),
        orderBy("email", "desc")
      )
    ),
    { enabled: true }
  );
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, "users", uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, "users", uid), data);
}

// Update suscription
export function updateUserSuscription(uid, data) {
  return updateDoc(doc(db, "users", uid), {
    ...data,
    fechaSuscripcion: serverTimestamp(),
  });
}


/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Fetch item data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getItem(id) {
  return getDoc(doc(db, "items", id)).then(format);
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

/**** MENSAJES ****/

// Create a new message
export function createMessage(data) {
  return addDoc(collection(db, "messages"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Create a new message
export function createFormContact(data) {
  return addDoc(collection(db, "formContacts"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Subscribe to all mensajes
export function useMessages() {
  return useQuery(
    ["messages"],
    createQuery(() =>
      query(
        collection(db, "messages"),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: true }
  );
}

// Update an descuento
export function updateMessage(id, data) {
  return updateDoc(doc(db, "messages", id), data);
}

// Delete an descuento
export function deleteMessage(id) {
  return deleteDoc(doc(db, "messages", id));
}

/****** PAGOS ******/

// Crear registro de pago exitoso
export function guardarPago(data, product, uid) {
  return setDoc(doc(db, "pagos", data.id), {
    ...data,
    product: product,
    uid: uid,
    createdAt: serverTimestamp(),
  });
}

// Crear registro de curso pagado en usuario
export function guardarCursoEnUsuario(data, product, uid) {
  return setDoc(doc(db, "cursosPagados", data.id), {
    cursoID: product.description,
    product: product,
    uid: uid,
    createdAt: serverTimestamp(),
  });
}

// Crear registro de suscripcion pagada en usuario
export function guardarSuscripcionEnUsuario(data, product, uid) {
  return setDoc(doc(db, "suscripcionesPagadas", data.id), {
    suscripcion: product.description,
    product: product,
    uid: uid,
    createdAt: serverTimestamp(),
  });
}

// Subscribe to all items by owner
export function usePagosPorUsuario(owner) {
  return useQuery(
    ["pagos", { owner }],
    createQuery(() =>
      query(
        collection(db, "pagos"),
        where("uid", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Subscribe to cursosPagados by User and Curso
export function useCursoPagado(usuario, curso) {
  return useQuery(
    ["cursosPagados", { usuario }],
    createQuery(() =>
      query(
        collection(db, "cursosPagados"),
        where("uid", "==", usuario),
        where("cursoID", "==" , curso),
      )
    ),
    { enabled: true }
  );
}

/****** DESCUENTOS ******/

// Create a new descuento
export function createDescuento(data) {
  return setDoc(doc(db, "descuentos", data.codigo), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Subscribe to item data
export function useDescuento(id) {
  return useQuery(
    ["descuentos", { id }],
    createQuery(() => doc(db, "descuentos", id)),
    { enabled: !!id }
  );
}

// Fetch descuento data once
export function useDescuentoOnce(codigo) {
  return useQuery(
    ["descuentos", { codigo }],
    createQuery(() =>
      query(
        collection(db, "descuentos"),
        where("codigo", "==", codigo),
      )
    ),
    { enabled: true }
  );
}

export async function verificarCodigo(codigo) {
  const q = doc(db, "descuentos", codigo)
  const docSnap = await getDoc(q);
  if (docSnap.exists()) {
  return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
  return false;
    }
  }

// Subscribe to all descuentos
export function useDescuentos() {
  return useQuery(
    ["descuentos"],
    createQuery(() =>
      query(
        collection(db, "descuentos"),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: true }
  );
}

// Update an descuento
export function updateDescuento(id, data) {
  return updateDoc(doc(db, "descuentos", id), data);
}

// Delete an descuento
export function deleteDescuento(id) {
  return deleteDoc(doc(db, "descuentos", id));
}

/****** CURSOS ******/

// Subscribe to item data
export function useCursoOnce(id) {
  return useQuery(
    ["cursos", { id }],
    createQuery(() => doc(db, "cursos", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
/* export function useCursoOnce(id) {
  return useQuery(
    ["cursos", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "cursos", id)).then(format),
    { enabled: !!id }
  );
} */

// Subscribe to all Cursos por Fecha
export function useCursosPorFecha() {
  return useQuery(
    ["cursosPorFecha"],
    createQuery(() =>
      query(
        collection(db, "cursosPorFecha"),
        orderBy("fechaInicio", "asc")
      )
    ),
    { enabled: true }
  );
}

// Subscribe to all Cursos for KEY por Fecha
export function useInstanciasCursoPorFecha(key) {
  /* const fechaActual = Date(serverTimestamp())
  console.log(fechaActual) */
  return useQuery(
    ["cursosPorFecha"],
    createQuery(() =>
      query(
        collection(db, "cursosPorFecha"),
        where("key", "==", key),
        orderBy("fechaInicio", "desc")
      )
    ),
    { enabled: true }
  );
}

// Subscribe to all Cursos for KEY por Fecha
export function useInstanciasCursoPorFechaLimitadoPorFechaActual(key) {
  const now = moment(serverTimestamp());
  const fechaActual = now.format('YYYY-MM-DD HH:mm:ss')
  return useQuery(
    ["cursosPorFecha"],
    createQuery(() =>
      query(
        collection(db, "cursosPorFecha"),
        where("key", "==", key),
        where("fechaInicio", ">=", fechaActual),
      )
    ),
    { enabled: true }
  );
}

// Subscribe to item data
export function useCursoPorFecha(id) {
  return useQuery(
    ["cursosPorFecha", { id }],
    createQuery(() => doc(db, "cursosPorFecha", id)),
    { enabled: !!id }
  );
}

// Create a Curso por Fecha
export function createCursoPorFecha(data) {
  return addDoc(collection(db, "cursosPorFecha"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an Curso por Fecha
export function updateCursoPorFecha(id, data) {
  return updateDoc(doc(db, "cursosPorFecha", id), data);
}

// Delete an descuento
export function deleteCursoPorFecha(id) {
  return deleteDoc(doc(db, "cursosPorFecha", id));
}

/***INSCRIPCIONES ****/

// Create a Curso por Fecha
export function createInscripcion(nombreInscripcion, dataUser, data) {
  return setDoc(doc(db, "inscripcionCurso", nombreInscripcion), {
    ...data,
    ...dataUser,
    createdAt: serverTimestamp(),
  });
}

export function useInscripcion(id) {
  return useQuery(
    ["inscripcionCurso", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "inscripcionCurso", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all Cursos for KEY por Fecha
export function useInscritosPorInstancia(id) {
  return useQuery(
    ["inscripcionCurso"],
    createQuery(() =>
      query(
        collection(db, "inscripcionCurso"),
        where("id", "==", id),
      )
    ),
    { enabled: true }
  );
}

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
