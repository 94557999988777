import React from "react";
import { Button } from "reactstrap";
import LongContent from "./LongContent";
import PDFTerminosyConciciones from "../assets/pdf/2022_Intellectus_eXD_Terminos_y_Condiciones_Brochure.pdf"

function LegalTerms(props) {
  return (
    <LongContent>
      <h1>Términos de Servicio</h1>
      {/* <p>
        This document is a placeholder. You should replace this with your own
        Terms of Service or create one using&nbsp;
        <a target="_blank" href="https://avodocs.com">
          avodocs.com
        </a>
        ,&nbsp;
        <a target="_blank" href="https://getterms.io">
          getterms.io
        </a>{" "}
        or&nbsp;
        <a target="_blank" href="https://termly.io">
          termly.io
        </a>
        . We've included some example HTML structure below to make adding your
        content easier ↴
      </p> */}
      <p>
        Estos Términos de servicio rigen el uso de {props.domain} y cualquier
         servicios proporcionados por {props.companyName}. Al acceder a {props.domain},
         usted acepta cumplir con estos Términos de servicio y cumplir con todos
         leyes y regulaciones aplicables.
      </p>
      <p>
        Si no está de acuerdo con estos Términos de servicio, tiene prohibido
         usar o acceder a {props.domain} y de usar cualquier otro servicio que
         proveer. Nos reservamos el derecho de revisar y modificar cualquiera de estos Términos de
         Servicio a nuestro exclusivo criterio. Al hacerlo, actualizaremos esta página.
         Cualquier cambio a estos Términos de servicio entrará en vigencia inmediatamente a partir de
         la fecha de publicación.
      </p>
      <p>Esta política se actualizó por última vez el 23 de marzo de 2022.</p>
      <h2>Términos y Condiciones para Participantes y/o Clientes.</h2>
      <p>
        Es importante que lea nuestros términos y condiciones, sí tiene alguna duda de nuestros servicios por favor comuníquese con nosotros enviando un correo a <a href={`mailto:info@Intellectus-eXD.mx`} >info@Intellectus-eXD.mx</a>
      </p>
      <p>
        Al inscribirse a un evento y/o contratar nuestros servicios usted acepta el contenido de:
      </p>
      <ol>
        <li>
          Política de Privacidad.
        </li>
        <li>
          Código de Conducta del Participante y/o Cliente.
        </li>
        <li>Términos y condiciones.</li>
      </ol>
      <h2>Código de Conducta del Participante y/o Cliente</h2>
      <p>El objetivo de Intellectus e XD es, el de elevar el potencial para aprender, el talento y habilidades técnicas de cada uno de los Participantes y/o Clientes y fomentar las relaciones interpersonales positivas. El Código de Conducta es la política oficial que gestionamos para asegurar el éxito académico y profesional</p>
      <p>Por lo anterior, el Participante y/o Cliente:</p>
      <ol>
        <li>
          Toma el control de su propio aprendizaje y su capacidad para alterar positivamente los resultados del evento, curso seminario y/o servicios recibidos, mediante el empleo de su experiencia y aplicación de las destrezas apropiadas
        </li>
        <li>
          Acepta el uso del modelo educativo basado en la evidencia, con enfoque en la solución de problemas y casos prácticos
        </li>
        <li>Acepta y reconoce la propiedad intelectual y
derechos de autor que Intellectus-eXD posee
de cada uno de sus materiales en HTML,
textos, gráficos, clips de audio, clips de video,
código fuente y/u objeto y todas las demás
obras (incluidas las marcas comerciales y los
nombres) contenidos en el Sitio Web y/o
proporcionados de otro modo a los
Participantes y/o Clientes Salvo que se indique
expresamente lo contrario y exista autorización
escrita de E-Smart Solutions Businees, S.C.
y/o sus representantes</li>
      </ol>
      <h2>Términos y Condiciones</h2>
      <p>Al participar en cualquier evento, curso presencial, curso en linea, seminario, asesoramiento provisto por Intellectus e XD el Participante y/o Cliente</p>
      <ol>
        <li>Reconoce que los precios u honorarios por servicio le han sido informados con anticipación mediante su publicación o presentación de carta propuesta</li>
        <li>Con la inscripción o aceptación de propuesta de servicios, se considera como aceptado el precio y otras condiciones (según sea el caso) Este precio será sujeto al I.V.A u otros impuestos correspondientes a pagar, los cuales deben estar identificados en la(s) factura(s), a menos que se establezca lo contrario</li>
        <li>Ante la F alta de pago, nos reservamos a nuestra entera discreción, suspender su participación en el evento o suspender la prestación de servicios hasta el momento en que hayamos recibido el pago pendiente vencido</li>
        <li>Cancelación Enviar su solicitud por escrito a <a href={`mailto:info@Intellectus-eXD.mx`} > info@Intellectus-eXD.mx</a> y en caso de
proceder, el reembolso se realizará dentro
de los 7 ( días hábiles siguientes,
considerando los siguientes porcentajes de
descuento aplicable, con el que se cubren
gastos de administración y otros incurridos
Sí la a ceptación de la cancelación se genera
i) a ntes del inicio del evento se reembolsa
100 ii{`)`} habiendo transcurrido hasta el 10
del evento o servicio se descuenta 10 o iii{`)`}
habiendo transcurrido más del 10 del
evento o servicio, se emitirá una nota de
crédito por el monto del servicio o evento
no utilizado, la cual será válida para
cualquiera de nuestros eventos futuros</li>
      </ol>
      <Button className="btn-round ml-1" color="danger" type="button" href={PDFTerminosyConciciones} >Descargar Términos y Condiciones</Button>
    </LongContent>
  );
}

export default LegalTerms;
