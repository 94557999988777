/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import RedesSociales from "./RedesSociales";
import { useAuth } from "../util/auth";
import AuthenticationButton from "./AuthenticationButton";
import Navegacion from "./Navegacion";
import { LinkContainer } from "react-router-bootstrap";


export default function ColorNavbarWhite(props) {

  const auth = useAuth();
  
  return (
    <>
      <Navbar className="bg-white navbar" expand="lg">
            <Container>
              <div className="navbar-translate">
                <LinkContainer to="/">
                  <NavbarBrand>
                    <img
                      className="d-inline-block align-top"
                      src={props.logo}
                      alt="Logo"
                      height="30"
                    />
                  </NavbarBrand>
                </LinkContainer>
                <button className="navbar-toggler" id="example-header-2">
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <UncontrolledCollapse navbar toggler="#example-header-2">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <a style={{color: 'red'}} href="/" onClick={(e) => e.preventDefault()}>
                        eXD• <span>Bulding Blocks</span>
                      </a>
                    </Col>
                    <Col className="collapse-close text-right" xs="6">
                      <button className="navbar-toggler" id="example-header-2">
                        <i className="tim-icons icon-simple-remove" />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="mx-auto" navbar>
                    
                </Nav>
                <Nav className="nav navbar-right" navbar>
                  {!auth.user && <RedesSociales />}
                  {auth.user && <Navegacion />}
                  <NavItem className="p-0">
                    <AuthenticationButton />
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
    </>
  );
}
