import React, { useEffect, useState } from 'react';
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { cursos } from '../util/datos'
import Select from 'react-select'
import { Button } from 'reactstrap'
import TablaInscripcionesPorCurso from './TablaInscripcionesPorCurso';


function DashboardAdminInscripciones() {

  const [opciones, setOpciones] = useState(null);
  const [curso, setCurso] = useState('');

  const { handleSubmit, control } = useForm();

  useEffect(() => {
    if (cursos) { 
      const temp = cursos.map(function (curso) {
        return { value: {key: curso.key, nombre: curso.nombre}, label: curso.nombre };
        });
        setOpciones(temp)
      }
    }, []);
    
    const onSubmit = (data) => {
      //console.log(data.curso.key);
      setCurso('')
      setCurso(data.curso.key);
    }

    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formInscripciones">
            <Controller
                control={control}
                defaultValue=""
                name="curso"
                render={({ onChange, value, name, ref }) => (
                  <Select
                    inputRef={ref}
                    className="react-select react-select-primary mb-2"
                    placeholder="Selecciona Curso"
                    /* classNamePrefix="react-select" */
                    options={opciones}
                    onChange={(val) => onChange(val.value)}
                  />
                )}
            />
          </Form.Group>
          
          
          <Button size="lg" variant="primary" type="submit" >
        <span>Cargar Instancia del Curso Seleccionado</span>

                    
        </Button>
        </Form>
        {curso && <TablaInscripcionesPorCurso keyCurso={curso} />}
      </>
    );
}

export default DashboardAdminInscripciones;
