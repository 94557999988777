import React from 'react';
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

function ModeloNegocio() {
    
    return (
        
        <div className="section blogs-2" id="blogs-2" style={{backgroundColor: '#fff'}}>
          <Container fluid >
            <header
                className="SectionHeader"
            >
                <h2
                style={{color: '#000000'}}
                className = "font-weight-bold mb-4 text-center container">
                Todo lo que necesitas para comenzar
                </h2>
            </header>
            <Row className="align-items-center">
              <Col xs="12">
                <Card
                  className="card-blog card-background"
                  data-animation={true}
                >
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("../assets/img/mission.jpg").default +
                        ")",
                    }}
                  />
                  <CardBody>
                    <div className="content-bottom text-center">
                        <h3 style={{marginBottom:'2', color: 'white'}}>Ensamble infinito de ideas, creatividad, información, tecnología, capacitación y asesoría de negocios.</h3>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
    )
}

export default ModeloNegocio
