import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormAlert from './FormAlert';
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { deleteCursoPorFecha } from '../util/db';

function ModalEliminarInstanciaCurso(props) {

    const [pending, setPending] = useState(false);
    const [formAlert, setFormAlert] = useState(null);

    const onSubmit = () => {
    setPending(true);

    const query = deleteCursoPorFecha(props.id)
      

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone(true);
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

    return (
    <Modal show={true} centered={true} animation={false} onHide={props.onDone}>
      <Modal.Header closeButton={true}>
        Eliminar Instancia de Curso
      </Modal.Header>
      <Modal.Body>
        {formAlert && (
          <FormAlert type={formAlert.type} message={formAlert.message} />
        )}
        <h2 style={{color: 'black'}}>Cofirmas que desear eliminar el mensaje. Se eliminara tambien la informacion de los inscritos a los cursos. Esta accion ya no se puede deshacer</h2>
          <Button size="lg" variant="danger" type="submit" onClick={() => {onSubmit();}} disabled={pending}>
            <span>Eliminar</span>

            {pending && (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden={true}
                className="ml-2"
              >
                <span className="sr-only">Eliminando...</span>
              </Spinner>
            )}
          </Button>
      </Modal.Body>
    </Modal>
    );
}

export default ModalEliminarInstanciaCurso;
