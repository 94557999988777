import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
  return (
    <LongContent>
      <h1>Politica de Privacidad</h1>
      <p>
        Su privacidad es importante para nosotros. Es nuestra política respetar su privacidad. y cumplir con cualquier ley y reglamento aplicable con respecto a la información que recopilamos sobre usted a través de este sitio web, {props.domain}, y a través de cualquier servicio asociado y plataformas de terceros que podamos usar.</p>
      <p>
        Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos de su conocimiento que Jorge Iván Valdivia Ramos, con domicilio en Calle 25 #300, Col. Prohogar, Azcapotzalco, CDMX 02600 es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.
      </p>
      <p>
        Su información personal será utilizada para las siguientes finalidades: proveer los servicios y productos que ha solicitado; notificarle sobre nuevos servicios o productos que tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios en los mismos; elaborar estudios y programas que son necesarios para determinar hábitos de consumo; realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de los mismos; evaluar la calidad del servicio que brindamos, y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.
      </p>  
      <p>
        Su privacidad es importante para nosotros. Es nuestra política respetar su privacidad.
         y cumplir con cualquier ley y reglamento aplicable con respecto a la
         información que recopilamos sobre usted a través de este sitio web, ,
         y a través de cualquier servicio asociado y plataformas de terceros que podamos
         usar.
      </p>
      <h2>Información que recopilamos</h2>
      <p>
        La información personal es cualquier información sobre usted que pueda utilizarse para
         identificarte. Cualquier información que recopilemos puede recopilarse automáticamente
         (es decir, información que sus dispositivos nos envían automáticamente cuando
         acceder a nuestros servicios) o proporcionados voluntariamente (es decir, información que
         proporcionarnos consciente y activamente).
      </p>
      <h2>Información recopilada automáticamente</h2>
      <p>
        Nuestros servidores registran automáticamente los datos estándar proporcionados por su navegador web
         cuando visita nuestro sitio web. Estos datos pueden incluir la IP de su dispositivo
         dirección, tipo de dispositivo, sistema operativo, tipo y versión del navegador, el
         páginas que visita, la hora y fecha de su visita, el tiempo dedicado a cada
         página y otros detalles sobre su visita o dispositivo.
      </p>
      <p>Es importante informarle que usted tiene derecho al Acceso, Rectificación y Cancelación de sus datos personales, a Oponerse al tratamiento de estos o a revocar el consentimiento que para dicho fin nos haya otorgado.</p>
      <p>Para ello, es necesario que envíe la solicitud en los términos que marca la Ley en su Art. 29 a Jorge Iván Valdivia Ramos, ubicado en Calle 25 #300, Col. Prohogar, Azcapotzalco, CDMX 02600, o bien, se comunique al teléfono (55) 72 9277 5173 o vía correo electrónico a jiyu.mx@gmail.com, el cual solicitamos confirme vía telefónica para garantizar su correcta recepción.</p>
      <p>En caso de que no desee recibir mensajes promocionales de nuestra parte, puede enviarnos su solicitud por medio de la dirección electrónica: jiyu.mx@gmail.com</p>
      <h2>IMPORTANTE</h2>
      <p>
        Cualquier modificación a este Aviso de Privacidad podrá consultarlo en https://intellectus-exd.com.mx. Fecha de última actualización: 25/Marzo/2022
      </p>
      {/* <h3>Another subheader</h3>
      <p>Another paragraph of text</p>
      <h2>Another main header</h2>
      <p>Another paragraph of text</p> */}
    </LongContent>
  );
}

export default LegalPrivacy;
