import React from 'react';
import PagosSection from '../components/PagosSection';
import Meta from '../components/Meta';
import { requireAuth } from '../util/auth';

function PagosPage() {
    return (
    <>
        <Meta title="Administracion" />
        <PagosSection
            bg="white"
            textColor="black"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title="Mis Pagos realizados"
            subtitle=""
        />
    </>    
        
        
  );
}

export default requireAuth(PagosPage);
