import React from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import {  useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import InicioCursos from "./InicioCursos";

function AdminSection(props) {
  const auth = useAuth();
  const router = useRouter();

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
          className="text-center"
        />

        {router.query.paid && auth.user.planIsActive && (
          <Alert
            variant="success"
            className="text-center mx-auto mb-5"
            style={{ maxWidth: "400px" }}
          >
            Estas suscrito al plan {auth.user.planId}
            <span className="ml-2" role="img" aria-label="party">
              🥳
            </span>
          </Alert>
        )}

        <Row>
          <Col lg={12} className="mt-4 mt-lg-0">
            <InicioCursos />
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            {/* <DashboardItems /> */}
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default AdminSection;
