import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./FaqSection.scss";

function FaqSection(props) {
  // Object to store expanded state for all items
  const [expanded, setExpanded] = useState({});
  // Set an item's expanded state
  const setExpandedItem = (index, isExpanded) => {
    setExpanded({
      ...expanded,
      [index]: isExpanded,
    });
  };

  const items = [
    {
      question: "¿Como puedo inscribirme a un curso?",
      answer:
        "Lo primero que debes de hacer es registrarte, puedes hacerlo con tu correo electrónico o tu cuenta de Gmail. Una vez registrado tendrás habilitados los botones para pago dentro de la sección del curso de tu elección. Solo debes completar el pago mediante Paypal, y una vez realizado, tendrás habilitado el botón para inscribirte al curso que hayas adquirido, seleccionando el horario disponible más conveniente para ti",
    },
    {
      question: "¿Como puedo realizar el pago de un curso?",
      answer:
        "Solo debes registrare dentro de la página para que se habilite el botón de pago dentro del curso de tu elección. Desde ahí puedes realizar el pago con tu misma cuenta de Paypal o con tu Tarjeta de Credito",
    },
    {
      question: "¿Si ya me inscribí a un curso, puedo des inscribirme y seleccionar otro horario del mismo curso?",
      answer:
        "Si. Solamente selecciona, dentro del curso donde deseas hacer el cambio, el botón Inscribirse nuevamente, y selecciona el horario disponible que más te convenga",
    },
    {
      question: "Compre un curso, pero no hay un horario disponible",
      answer:
        "Puedes enviar un mensaje dentro de la plataforma, para que nuestro equipo en breve te ofrezca una solución",
    },
    {
      question: "¿Donde puedo conocer el detalle de pago de los cursos que he adquirido?",
      answer:
        "Una vez iniciada tu sesión dentro de la página, en la sección de Mis Pagos puedes conocer los detalles del pago y cursos que has adquirido",
    },
    {
      question: "Hay un error en la página. ¿Donde puedo contactar al Soporte Técnico?",
      answer:
        "Nuestro equipo de Soporte Técnico esta listo para atenderte. Envía un correo electrónico a jiyu.mx@gmail.com y en breve te contactaremos para ayudarte. Nuestro horario de atención es de Lunes a Viernes de 9 a 18 hrs.",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />

        {items.map((item, index) => (
          <article
            className="FaqSection__faq-item py-4"
            onClick={() => {
              setExpandedItem(index, !expanded[index]);
            }}
            key={index}
          >
            <h4>
              <span className="text-primary mr-3">
                <i
                  className={
                    "fas" +
                    (expanded[index] ? " fa-minus" : "") +
                    (!expanded[index] ? " fa-plus" : "")
                  }
                />
              </span>
              <div style={{color: "black"}}>
              {item.question}
              </div>
            </h4>

            {expanded[index] && <div className="mt-3">{item.answer}</div>}
          </article>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
