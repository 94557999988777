import React from 'react'
import { Modal } from 'react-bootstrap';
import { useInscritosPorInstancia } from '../util/db';
import FormAlert from './FormAlert';
import LongContent from './LongContent';
import { Spinner } from 'react-bootstrap';
import { Col, Container, Row, Table } from 'reactstrap';
import Moment from 'react-moment';
import 'moment/locale/es';

function ModalInscritos(props) {

    const {
    data: datos,
    status: datosStatus,
    error: datosError,
    } = useInscritosPorInstancia(props.id);

    const datosAreEmpty = !datos || datos.length === 0;

  return (
    <Modal show={true} centered={true} animation={false} onHide={props.onDone}>
      <Modal.Header closeButton={true}>
          <Container>
            <Row>
                <Col>Registrados en el curso "{props.nombreCurso}"</Col>
            </Row>
            <Row className="mt-2">
                <Col>Fecha de inicio <Moment format="D MMMM YYYY">{props.fechaInicio}</Moment></Col>
            </Row>    
          </Container>
      </Modal.Header>
      <Modal.Body>
        <LongContent>
        
        {datosError && (
        <div className="mb-3">
          <FormAlert type="error" message={datosError.message} />
        </div>
        )}
        
        {(datosStatus === "loading" || datosAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {datosStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Cargando...</span>
                </Spinner>
              )}

              {datosStatus !== "loading" && datosAreEmpty && (
                <>No hay ningun inscrito en la instancia de curso seleccionada.</>
              )}
            </div>
        )}
        
        <Table responsive>
            <thead>
                <tr>
                    <th className="text-center">Nombre</th>
                    <th className="text-center">Email</th>
                </tr>
            </thead>
            <tbody>
            {datosStatus !== "loading" && datos && datos.length > 0 && (
              datos.map((inscrito, index) => (
                <tr key={index}>
                  <td className="text-center">{inscrito.name}</td>
                  <td className="text-center">{inscrito.email}</td>
                </tr>
              ))
            )}    
            </tbody>
          </Table>
        </LongContent>
      </Modal.Body>
    </Modal>
  )
}

export default ModalInscritos