import React from "react";
import Meta from "../components/Meta";
import ContactSection from "../components/ContactSection";
import FormSection from "../components/FormSection";

function FormularioPage(props) {
  return (
    <>
      <Meta title="Formulario" />
      <FormSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Formulario"
        subtitle="Llena el formulario para descargar la información de los planes que tenemos para tí"
        buttonText="Enviar"
        buttonColor="danger"
        showNameField={true}
      />
    </>
  );
}

export default FormularioPage;
