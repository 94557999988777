import React from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useAuth } from '../util/auth';

const Navegacion = () => {

  const auth = useAuth();

    return (
      <UncontrolledDropdown nav>
        <DropdownToggle
          caret
          color="default"
          data-toggle="dropdown"
          href="#pablo"
          nav
          onClick={(e) => e.preventDefault()}
        >
          <i className="fa fa-cogs d-lg-none d-xl-none" />
          Navegacion
        </DropdownToggle>
        <DropdownMenu className="dropdown-with-icons">
          {/* <DropdownItem tag={Link} to="/dashboard">
            <i className="tim-icons icon-bullet-list-67" />
            Dashboard
          </DropdownItem>
          <DropdownItem tag={Link} to="/cursos">
            <i className="tim-icons icon-bullet-list-67" />
            Cursos
          </DropdownItem> */}
          <DropdownItem tag={Link} to="/settings/general">
            <i className="tim-icons icon-chat-33" />
            Opciones
          </DropdownItem>
          <DropdownItem tag={Link} to="/pagos">
            <i className="tim-icons icon-badge" />
            Mis Pagos
          </DropdownItem>
          {auth.user.perfil === 'admin' &&
          <>  
          <DropdownItem tag={Link} to="/admin">
            <i className="tim-icons icon-badge" />
            Admin Cursos
          </DropdownItem>
          <DropdownItem tag={Link} to="/codigos">
            <i className="tim-icons icon-badge" />
            Codigos Promocionales
          </DropdownItem>
          <DropdownItem tag={Link} to="/mensajes">
            <i className="tim-icons icon-badge" />
            Mensajes
          </DropdownItem>
          <DropdownItem tag={Link} to="/inscripciones">
            <i className="tim-icons icon-badge" />
            Inscripciones
          </DropdownItem>
          </>
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    );
}

export default Navegacion;
