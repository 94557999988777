import React from 'react';
import Meta from '../components/Meta';
import InscripcionesSection from '../components/InscripcionesSection';
import { requireAuth } from '../util/auth';

function InscripcionesPage() {
    return (
        <>
        <Meta title="Inscripciones" />
        <InscripcionesSection
            bg="white"
            textColor="black"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title="Inscripciones"
            subtitle="Inscripciones por Curso"
        />
    </>   
    );
}

export default requireAuth(InscripcionesPage);
