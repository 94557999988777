import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormAlert from './FormAlert';
import Form from "react-bootstrap/Form";
import FormField from "./FormField";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { createDescuento, updateDescuento, useDescuento } from '../util/db';

function EditDescuentoModal(props) {

    const [pending, setPending] = useState(false);
    const [formAlert, setFormAlert] = useState(null);

    const { register, handleSubmit, errors } = useForm();

    const { data: itemData, status: itemStatus } = useDescuento(props.id);

    if (props.id && itemStatus !== "success") {
    return null;
    }

    const onSubmit = (data) => {
    setPending(true);

    const query = props.id
      ? updateDescuento(props.id, data)
      : createDescuento( data );

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone(true);
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

    return (
    <Modal show={true} centered={true} animation={false} onHide={props.onDone}>
      <Modal.Header closeButton={true}>
        {props.id && <>Actualizar</>}
        {!props.id && <>Crear</>}
        {` `}Descuento
      </Modal.Header>
      <Modal.Body>
        {formAlert && (
          <FormAlert type={formAlert.type} message={formAlert.message} />
        )}

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formDescuento">
            <FormField
              size="lg"
              name="codigo"
              type="text"
              label="Codigo"
              placeholder="Codigo de Promocion"
              defaultValue={itemData && itemData.codigo}
              error={errors.codigo}
              autoFocus={true}
              inputRef={register({
                required: "Ingresa un codigo de Promocion",
              })}
            />
            <FormField
              size="lg"
              name="porcentajeDescuento"
              type="number"
              label="Porcentaje de Descuento (en numero entero)"
              placeholder="0"
              defaultValue={itemData && itemData.porcentajeDescuento}
              error={errors.porcentajeDescuento}
              autoFocus={true}
              inputRef={register({
                required: "Ingresa un Porcentaje de Descuento, en numero entero",
              })}
            />
          </Form.Group>
          <Button size="lg" variant="primary" type="submit" disabled={pending}>
            <span>Guardar</span>

            {pending && (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden={true}
                className="ml-2"
              >
                <span className="sr-only">Cargando...</span>
              </Spinner>
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
    );
}

export default EditDescuentoModal;
