import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap"


const LoginButton = () => {

  return (
    <Link to="/auth/signin" tag={Link}>
      <Button
        className="nav-link d-lg-block"
        color="danger"
        target="_blank"
      >
        <i className="tim-icons icon-spaceship" />
        {"Iniciar Sesion"}
      </Button>
    </Link>
  );
};

;

export default LoginButton;
