import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import FormField from "./FormField";
import contact from "../util/contact";
//import { createMessage } from "../util/db"
import { createFormContact } from "../util/db";
import { storage } from "../util/storage";
import { getDownloadURL, ref } from "firebase/storage";
import ReactDatetime from "react-datetime";
import "moment/locale/es";
import { ButtonGroup, Button as ButtonRS } from "reactstrap";
import classNames from "classnames";
import { Card } from "react-bootstrap";

//Funciones auxiliares para cambiar Date a formato DD/MM/YYYY
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
        }

function FormDownload(props) {
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [pdfLink, setPdfLink] = useState(null);
  const [fecha, setFecha] = useState();
  const [tipoUsuario, setTipoUsuario] = useState("alumnos");
  const { handleSubmit, register, errors, reset } = useForm();

  const [formData, setFormData] = useState({
    nombre: "",
    fechaNacimiento: "",
    fumas: "",
    plan: "",
    monto: "",
    whats: "",
    email: "",
    padecimiento: "",
    hijos: [],
  });


  const [showChildrenFields, setShowChildrenFields] = useState(false);
  const [children, setChildren] = useState([{ nombre: "", edad: "" }]);

  const pdfRef = ref(storage, "ISUZU_PLANES INDIVIDUALES_2024.pdf");

  const handleFecha = (fecha) => {
    var myDate = new Date(fecha); // Your timezone!
    setFecha(formatDate(myDate));
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePlanChange = (e) => {
    const selectedPlan = e.target.value;
    setFormData({ ...formData, plan: selectedPlan });
    setShowChildrenFields(
      selectedPlan === "Garantizar la Educación de tus hijos"
    );
  };

  const handleAddChild = () => {
    setChildren([...children, { nombre: "", edad: "" }]);
  };

  const handleChildChange = (index, e) => {
    const newChildren = children.map((child, i) => {
      if (i === index) {
        return { ...child, [e.target.name]: e.target.value };
      }
      return child;
    });
    setChildren(newChildren);
  };

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    const datos = {
      ...data,
      fechaNacimiento: fecha,
      hijos: children,
    };

    console.log(datos);

    createFormContact(datos)
      .then(() => {
        // Clear form
        reset();
        // Show success alert message
        setFormAlert({
          type: "success",
          message:
            "Muchas gracias. Puedes descargar la información en el siguiente enlace.",
        });
        getDownloadURL(pdfRef)
          .then((pdfUrl) => {
            setPdfLink(pdfUrl);
          })
          .catch((error) => {
            setFormAlert({
              type: "error",
              message: error.message,
            });
          });
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <>
      {formAlert && (
        <FormAlert type={formAlert.type} message={formAlert.message} />
      )}
      {pdfLink && (
        <>
          <Card>
            <Card.Body className="d-flex flex-column text-center align-items-center p-4 bg-info">
              <Card.Text className="mt-4">
                <a
                  href={pdfLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  Descargar PDF
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
          <p></p>
        </>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          {props.showNameField && (
            <Form.Group as={Col} xs={12} sm={6} controlId="formName">
              <label>Nombre Completo:</label>
              <FormField
                style={{ color: "black" }}
                size="lg"
                name="name"
                type="text"
                placeholder="Nombre Completo"
                error={errors.name}
                inputRef={register({
                  required: "Ingresa tu Nombre Completo",
                })}
              />
            </Form.Group>
          )}

          <Form.Group
            as={Col}
            xs={12}
            sm={props.showNameField ? 6 : 12}
            controlId="formEmail"
          >
            <label>Email:</label>
            <FormField
              style={{ color: "black" }}
              size="lg"
              name="email"
              type="email"
              placeholder="Email"
              error={errors.email}
              inputRef={register({
                required: "Ingresa tu Email",
              })}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formMessage">
          {/* <FormField
            style={{ color: "black" }}
            size="lg"
            name="message"
            type="textarea"
            placeholder="Mensaje"
            rows={5}
            error={errors.message}
            inputRef={register({
              required: "Ingresa tu mensaje",
            })}
          /> */}
          <Form.Group>
            <label className="form-control-label" htmlFor="exampleDatepicker">
              Fecha de Nacimiento
            </label>
            <ReactDatetime
              locale="es"
              inputProps={{
                placeholder: "Selecciona tu Fecha de Nacimiento",
              }}
              //onChange={(fecha) => setFecha(fecha.toDate())}
              onChange={(fecha) => handleFecha(fecha)}
              timeFormat={false}
              closeOnSelect={true}
            ></ReactDatetime>
          </Form.Group>

          <Form.Group>
            <label>Fumas:</label>
            <label>
              <input
                type="radio"
                name="fumas"
                value="Si"
                onChange={handleChange}
                required
              />{" "}
              Sí
            </label>
            <label>
              <input
                type="radio"
                name="fumas"
                value="No"
                onChange={handleChange}
                required
              />{" "}
              No
            </label>
          </Form.Group>

          <Form.Group>
            <label>¿Qué plan te interesa explorar?</label>
            <select name="plan" onChange={handlePlanChange} required>
              <option value="">Selecciona un plan</option>
              <option value="Plan de Excesos">Plan de Excesos</option>
              <option value="Plan Gastos Médicos Mayores Individual para familiar">
                Plan Gastos Médicos Mayores Individual para familiar
              </option>
              <option value="Plan para tu Retiro">Plan para tu Retiro</option>
              <option value="Garantizar la Educación de tus hijos">
                Garantizar la Educación de tus hijos
              </option>
              <option value="Ahorro con Protección para proyecto personal">
                Ahorro con Protección para proyecto personal
              </option>
            </select>
          </Form.Group>

          <Form.Group>
            {showChildrenFields &&
              children.map((child, index) => (
                <div key={index}>
                  <label>Nombre de tu hijo {index + 1}:</label>
                  <input
                    type="text"
                    name="nombre"
                    value={child.nombre}
                    onChange={(e) => handleChildChange(index, e)}
                    required
                  />

                  <label>Edad de tu hijo {index + 1}:</label>
                  <input
                    type="number"
                    name="edad"
                    value={child.edad}
                    onChange={(e) => handleChildChange(index, e)}
                    required
                  />
                </div>
              ))}
          </Form.Group>
          {showChildrenFields && (
            <Form.Group>
              <button type="button" onClick={handleAddChild}>
                Agregar otro hijo
              </button>
            </Form.Group>
          )}

          <Form.Group as={Col} xs={12} sm={6} controlId="formMonto">
            <label>Monto Mensual a Aportar:</label>
            <FormField
              style={{ color: "black" }}
              size="lg"
              name="monto"
              type="number"
              placeholder="Monto Mensual a Aportar"
              error={errors.monto}
              inputRef={register({
                required: "Ingresa el monto que estas dispuesto a aportar",
              })}
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={6} controlId="formWhatsApp">
            <label>WhatsApp:</label>
            <FormField
              style={{ color: "black" }}
              size="lg"
              name="whatsapp"
              type="text"
              placeholder="Numero de WhatasApp"
              error={errors.whatsapp}
              inputRef={register({
                required: "Ingresa tu número de WhatsApp",
              })}
            />
          </Form.Group>

          <Form.Group>
            <label>Tienes algún padecimiento actualmente?</label>
            <label>
              <input
                type="radio"
                name="padecimiento"
                value="Si"
                onChange={handleChange}
                required
              />{" "}
              Sí
            </label>
            <label>
              <input
                type="radio"
                name="padecimiento"
                value="No"
                onChange={handleChange}
                required
              />{" "}
              No
            </label>
          </Form.Group>

          <Form.Group>
            {formData.padecimiento === "Si" && <label>¿Cuál?</label>}
            {formData.padecimiento === "Si" && (
              <FormField
                style={{ color: "black" }}
                size="lg"
                name="padecimientoDetalles"
                type="text"
                placeholder="Menciona el padecimiento"
                error={errors.padecimientoDetalles}
                inputRef={register({
                  required: "Que padecimiento presentas",
                })}
              />
            )}
          </Form.Group>
        </Form.Group>
        <Button
          variant={props.buttonColor}
          size="lg"
          type="submit"
          disabled={pending}
        >
          <span>{props.buttonText}</span>

          {pending && (
            <Spinner
              animation="border"
              size="sm"
              role="status"
              aria-hidden={true}
              className="ml-2"
            >
              <span className="sr-only">Enviando...</span>
            </Spinner>
          )}
        </Button>
      </Form>
    </>
  );
}

export default FormDownload;
